<template>
  <footer>
    <p>
      © <strong>Répertoire agroalimentaire Chaudière-Appalaches</strong>
      <time datetime="2022"> 2022</time>. Portail conçu par
      <em>
        <a
          href="https://conceptionwm.com"
          title="Lien vers le site de Conception WebMédia"
          >Conception WebMédia</a
        ></em
      >.
    </p>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
});
</script>

<style scoped lang="scss">
@import '@/assets/scss/_colors.scss';

a {
  color: $grisCaracteres;
  text-decoration: none;

  &:visited {
    color: $grisCaracteres;
  }

  &:focus,
  &:hover,
  &:active {
    text-decoration: underline;
  }
}

// balise <em> sans italique, simplement pour sémantique
em {
  font-style: normal;
}

footer {
  background-color: $grisPiedDePage;
  color: $grisCaracteres;
  p {
    background-color: $grisPiedDePage;
    color: $grisCaracteres;
    margin-bottom: 0;
  }
  height: 42px;
  padding-right: 50px;

  // flex
  // pour centrer texte en hauteur
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

// balise <strong> sans italique, simplement pour sémantique
strong {
  font-weight: normal;
}

@media screen and (max-width: 1000px) {
  footer {
    margin: 15px;
    padding: 0;
  }
}
</style>
