<template>
  <div class="hauteur-de-la-page">
    <Header v-if="!currentRouteIsInscription" />
    <router-view />
    <Footer v-if="!currentRouteIsInscription" />
    <CookieDialog primary_color="#7A9B06"></CookieDialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';

export default defineComponent({
  name: 'App',
  components: {
    Footer,
    Header,
  },
  computed: {
    currentRouteIsInscription() {
      return this.$route.name == 'PageInscription';
    },
  },
});
</script>

<style lang="scss">
// importations
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_fonts.scss';

// pour réinitialiser le style par défaut sur tous les éléments HTML
* {
  margin: 0;
  padding: 0;
}

button {
  font-size: 0.9em;
  cursor: pointer;
  padding: 5px 15px 5px 15px;
}

h1,
h2 {
  color: $bleuFonce;
  font-family: $pt;
  font-size: 36px;
}

input[type='checkbox'] {
  accent-color: $bleuFonce;
}

#app {
  // règles par défaut pour #app dans VueJS
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
  // fin règles par défaut pour #app dans VueJS

  // autres règles
  font-family: $roboto;
}

.hauteur-de-la-page {
  min-height: 100vh;

  // flex
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
