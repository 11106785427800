import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import PageEntreprises from '../views/PageEntreprises.vue';
import PageInscription from '../views/PageInscription.vue';
import CookiePolicy from '../views/CookiePolicy.vue';
import Page404 from '../views/Page404.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'PageEntreprises',
    component: PageEntreprises,
    meta: { cacheEnTetePiedPage: false, cacheJumbotron: false },
  },
  {
    path: '/inscription',
    name: 'PageInscription',
    component: PageInscription,
    meta: { cacheEnTetePiedPage: false, cacheJumbotron: false },
  },
  {
    path: '/politique-de-temoins',
    name: 'CookiePolicy',
    component: CookiePolicy,
    meta: { cacheEnTetePiedPage: false, cacheJumbotron: true },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: Page404,
    meta: { cacheEnTetePiedPage: false, cacheJumbotron: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
