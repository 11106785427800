<template>
  <nav>
    <router-link :to="'/'" title="Page d'accueil RACA" class="flex-gauche">
      <img
        src="../assets/img/repertoire-agroalimentaire-chaudiere-appalaches_logo.svg"
        alt="Logo Répertoire agroalimentaire Chaudière-Appalaches (RACA)"
      />
    </router-link>

    <div class="liens">
      <div>
        <router-link :to="'/inscription'" title="Page d'inscription au site"
          >Inscription</router-link
        >
      </div>
      <div>
        <a :href="loginLink()" title="Lien de connexion">Connexion</a>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Nav',
  methods: {
    loginLink(): string {
      return process.env.VUE_APP_BACKEND_API_URL + 'login';
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/scss/_colors.scss';

a {
  color: $bleuFonce;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

img {
  height: 83px;
  margin-left: 10px;
}

li {
  list-style-type: none;
}

nav {
  background-color: $blanc;
  min-height: 86px;
  padding: 20px 0px 20px 15px;

  // grid
  display: grid;
  align-items: center;
  grid-template-columns: 10fr 2fr;
}

ul {
  color: $bleuFonce;
}

.flex-gauche {
  display: flex;
  justify-content: center;
}

.liens {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-end; /* Aligner à droite */
}

a:link,
a:visited {
  text-decoration: none !important;
  font-weight: bold;
}

.router-link-active {
  font-weight: bold;
}

@mixin allMediaQueries() {
  a {
    color: white;
  }

  div.liens {
    display: flex;
    justify-content: center;
  }

  div.liens > div {
    color: $blanc;
    margin: 15px;
    padding: 5px 15px;

    background: $bleuPale;
  }

  img {
    margin-left: 0;
    margin-top: 15px;
    max-width: 100%;
    height: auto;
  }

  nav {
    display: flex;
    flex-flow: column;
    padding-left: 0;
  }
}

@media screen and (max-width: 500px) {
  @include allMediaQueries();
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  @include allMediaQueries();
}
</style>
