import axios from 'axios';
import { InscriptionDTO } from '@/types/Inscription/InscriptionDTO';

const inscription = {
  namespaced: true,
  actions: {
    async getMrcs({ commit }) {
      try {
        const data = await axios.get(
          `${process.env.VUE_APP_BACKEND_API_URL}api/mrcs`
        );
        return data.data;
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    getVilles() {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/villes`;
        axios
          .get(url)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    getConsentements() {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/consentements`;
        axios
          .get(url)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    demandeInscription(context, demande: InscriptionDTO) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/demandeInscription`;
        axios
          .post(url, demande)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            reject(message);
          });
      });
    },
  },
};

export default inscription;
