<script setup>
import CookiePreferences from './CookiePreferences.vue';
</script>
<template>
  <div v-if="displayDialog" class="dialog" id="cookiesPopup">
    <div class="dialog-header">
      <button class="dialog-header-button" @click="onClickCloseDialog()">
        X
      </button>
      <h3>Vos préférences en matière de confidentialité</h3>
    </div>
    <div class="dialog-body">
      <p>
        Pour offrir les meilleures expériences, nous utilisons des technologies
        telles que les témoins pour stocker et/ou accéder aux informations des
        appareils. Le fait de consentir à ces technologies nous permettra de
        traiter des données telles que le comportement de navigation ou les ID
        uniques sur ce site. Le fait de ne pas consentir ou de retirer son
        consentement peut avoir un effet négatif sur certaines caractéristiques
        et fonctions.
      </p>
      <div v-if="seePreferences" class="cookie-preferences">
        <CookiePreferences
          v-model:preferences="preferences"
        ></CookiePreferences>
      </div>
    </div>
    <div class="dialog-footer">
      <div class="dialog-footer-buttons">
        <button class="accept" :style="cssVars" @click="onClickButtonAccept()">
          Accepter
        </button>
        <button @click="onClickButtonRefuse()">Refuser</button>
        <button v-if="!seePreferences" @click="onClickSeePreferences()">
          Voir les préférences
        </button>
        <button v-else @click="onClickButtonPreferences()">
          Enregistrer les préférences
        </button>
      </div>
      <div class="dialog-footer-links">
        <router-link
          :to="'/politique-de-temoins'"
          title="Page d'inscription au site"
          >Politique de témoins
        </router-link>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="dialog-close" @click="onClickOpenDialog()">
      <p class="close-text">Vos préférences en matière de confidentialité</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['links', 'primary_color'],
  computed: {
    cssVars() {
      return {
        '--color': this.primary_color,
      };
    },
  },
  data() {
    return {
      displayDialog: true,
      seePreferences: false,
      preferences: {
        fonctionnel: true,
        preferences: true,
        statistics: true,
        marketing: true,
      },
    };
  },
  methods: {
    onClickOpenDialog() {
      this.displayDialog = true;
    },
    onClickCloseDialog() {
      this.displayDialog = false;
    },
    onClickSeePreferences() {
      this.seePreferences = true;
    },
    onClickButtonAccept() {
      this.setPreferencesProperties(true);
      this.setLocaleStorageCookies();
    },
    onClickButtonRefuse() {
      this.setPreferencesProperties(false);
      this.setLocaleStorageCookies();
    },
    onClickButtonPreferences() {
      this.setLocaleStorageCookies();
    },
    setPreferencesProperties(boolean) {
      for (const prop in this.preferences) {
        if (prop !== 'fonctionnel') {
          // eslint-disable-next-line no-prototype-builtins
          if (this.preferences.hasOwnProperty(prop)) {
            if (this.preferences[prop] === !boolean) {
              this.preferences[prop] = boolean;
            }
          }
        }
      }
    },
    setLocaleStorageCookies() {
      localStorage.setItem('preferences', JSON.stringify(this.preferences));
      this.onClickCloseDialog();
    },
  },
  mounted() {
    var preferences = window.localStorage.getItem('preferences');
    if (preferences === null) {
      this.displayDialog = true;
    } else {
      this.displayDialog = false;
    }
  },
};
</script>

<style scoped>
@-webkit-keyframes mc_slideIn {
  100% {
    bottom: 0;
  }
}

@keyframes mc_slideIn {
  100% {
    bottom: 0;
  }
}

@-webkit-keyframes mc_slideOut {
  100% {
    bottom: -35px;
  }
}

@keyframes mc_slideOut {
  100% {
    bottom: -35px;
  }
}
.dialog-close {
  position: fixed;
  right: 2rem;
  bottom: -35px;
  background-color: #fff;
  border-radius: 12px 12px 0px 0px;
  color: #000;
  animation: mc_slideOut 0.5s forwards;
}
.dialog-close:hover {
  position: fixed;
  right: 2rem;
  background-color: #fff;
  border-radius: 12px 12px 0px 0px;
  color: #000;
  animation: mc_slideIn 0.5s forwards;
}
.dialog-close p {
  font-size: medium;
  font-weight: bold;
  padding: 1rem;
  cursor: pointer;
}
/* Ajoutez des règles media query pour rendre le dialogue réactif */
@media (max-width: 768px) {
  .dialog {
    position: fixed;
    max-width: 100%; /* Ajustez la largeur en fonction de l'écran */
    bottom: 0; /* Placez-le en bas de l'écran */
    right: 0; /* Placez-le à droite de l'écran */
  }
}
.dialog {
  position: fixed;
  bottom: 10px; /* À ajuster selon vos besoins */
  right: 10px; /* À ajuster selon vos besoins */
  background-color: #fff; /* Fond du dialogue */
  padding: 10px; /* Espace intérieur du dialogue */
  border: 1px solid #ccc; /* Bordure du dialogue */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Ombre pour un effet de profondeur */

  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #000;
  border-radius: 20px;
  padding: 15px 15px 15px;
}
.dialog-header {
  width: 100%;
  margin-bottom: 1rem;
}
.dialog-header h3 {
  text-align: center;
}
.dialog-header-button {
  border: none;
  background-color: transparent;
  font-weight: 900;
  font-size: large;
  cursor: pointer;
  float: right;
}
.dialog-body {
  margin-bottom: 1rem;
}
.dialog-body p {
  font-size: 14px;
}
.cookie-preferences {
  margin-top: 1rem;
}
.dialog-footer {
  width: 100%;
}

.dialog-footer-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}
.dialog-footer-buttons button {
  padding: 10px;
  border: 1px solid #f2f2f2;
  border-radius: 6px 6px 6px 6px;
  color: #222222;
  background-color: #f9f9f9;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  margin: 10px;
}
button.accept {
  border: 1px solid var(--color);
  background-color: var(--color);
  color: white;
}
.dialog-footer-links {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;

  color: #222222;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
</style>
