<template>
  <div class="container">
    <div class="partners">
      <p class="titre-partner">Les partenaires</p>
      <div class="images">
        <div class="image-partner" v-for="image in imagesPartners" :key="image">
          <div>
            <img :src="image.url" :alt="image.alt" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FooterPartner',
  data() {
    return {
      imagesPartners: [],
    };
  },
  methods: {
    importAll(data) {
      data.keys().map((item) => {
        let image = {
          url: '',
          alt: '',
        };
        image.alt = item.replace('./', '').replace('.jpg', '');
        image.url = require('../assets/img/partners/' + item.replace('./', ''));
        this.imagesPartners.push(image);
      });
    },
  },
  mounted() {
    this.importAll(
      require.context('../assets/img/partners/', false, /\.(png|jpe?g|svg)$/)
    );
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_fonts.scss';

.container {
  background-color: #eaf0f4;
  color: $bleuFonce;
}

.partners {
  padding: 5%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.titre-partner {
  font-size: 27px;
  text-align: left;
  padding-bottom: 27px;
}
.images {
  //flex
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.image-partner {
  background-color: white;
  margin: 0px 10px 10px 0px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 150px;
  }
}
</style>
