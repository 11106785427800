<template>
  <aside>
    <div class="filtres">
      <div
        class="separateur"
        :key="key"
        v-for="(filtre, key) in filtres"
        v-bind:class="{
          hide: filtre.data.length <= 0 || hideFilterCategory(filtre),
        }"
      >
        <strong class="titre-principal" @click="toggleShow(filtre)">
          <h2>{{ filtre.nom }}</h2>
          <span class="chevron-up pr25" v-if="filtre.show"></span>
          <span class="chevron-down pr25" v-if="!filtre.show"></span>
        </strong>
        <div class="conteneur-cases" v-if="filtre.show">
          <div
            class="case"
            v-for="filtreItem of filtre.data"
            :key="filtreItem.id"
          >
            <div
              class="conteneur-une-case-un-libelle"
              v-if="filtreItem.entreprises_count !== 0"
            >
              <input
                type="checkbox"
                v-model="filtreItem.estActif"
                @change="onFiltreChange"
              />
              <span class="libelle">
                {{ capitalizeFirstLetter(filtreItem.nom) }}
                <span
                  v-if="
                    filtreItem.entreprises_count !== 0 &&
                    filtreItem.entreprises_count !== undefined
                  "
                >
                  ({{ filtreItem.entreprises_count }})
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script lang="ts">
import { defineComponent, inject, Ref } from 'vue';
import { Filtre } from '@/types/Filtre';
import { FiltreDTO } from '@/types/FiltreDTO';
import { FiltreType } from '@/types/FiltreType';
import RequeteRechercheHandler from '@/handlers/RequeteRechercheHandler';
import EventSynchronisationHandler from '@/handlers/EventSynchronisationHandler';
import { EventSynchronisationName } from '@/types/EventSynchronisationName';

export default defineComponent({
  name: 'FiltresEntreprises',
  events: ['filtreChangement', 'finChargementFiltres'],
  data() {
    return {
      filtres: {
        // typesTransactions: {
        //   nom: 'Types de transactions',
        //   data: new Array<Filtre>(),
        //   show: true,
        // },
        typeProductions: {
          nom: 'Types de production',
          data: new Array<Filtre>(),
          show: true,
        },
        typeClienteles: {
          nom: 'Types de clientèles',
          data: new Array<Filtre>(),
          show: false,
        },
        produits: {
          nom: 'Produits',
          data: new Array<Filtre>(),
          show: false,
        },
        volumes: {
          nom: 'Volumes',
          data: new Array<Filtre>(),
          show: false,
        },
        conservations: {
          nom: 'Type de conservation',
          data: new Array<Filtre>(),
          show: false,
        },
        specificites: {
          nom: 'Spécificité',
          data: new Array<Filtre>(),
          show: false,
        },
        certifications: {
          nom: 'Certification',
          data: new Array<Filtre>(),
          show: false,
        },
        conditionnements: {
          nom: 'Conditionnement',
          data: new Array<Filtre>(),
          show: false,
        },
        typeContenants: {
          nom: 'Type de contenants',
          data: new Array<Filtre>(),
          show: false,
        },
        dureeVies: {
          nom: 'Durée de vie',
          data: new Array<Filtre>(),
          show: false,
        },
        codeBarres: {
          nom: 'Code à barres',
          data: new Array<Filtre>(),
          show: false,
        },
        quantiteMinimaleCommandes: {
          nom: 'Quantité minimale de commandes',
          data: new Array<Filtre>(),
          show: false,
        },
        disponibilites: {
          nom: 'Disponibilités',
          data: new Array<Filtre>(),
          show: false,
        },
        permis: {
          nom: 'Permis',
          data: new Array<Filtre>(),
          show: false,
        },
        certificationInstallations: {
          nom: 'Certification installation',
          data: new Array<Filtre>(),
          show: false,
        },
      },
    };
  },
  setup() {
    const filtresRef = inject('filtres') as Ref<Map<string, Filtre>>;
    return {
      filtresRef,
    };
  },
  methods: {
    hideFilterCategory(filtre) {
      for (let i = 0; i < filtre.data.length; i++) {
        if (filtre.data[i].entreprises_count !== 0) {
          return false;
        }
      }
      return true;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleShow(filtre: { show: boolean }) {
      filtre.show = !filtre.show;
    },
    onFiltreChange() {
      RequeteRechercheHandler.restartTimeout();
      this.$emit('filtreChangement');
    },
    getFiltreListeWithAddidionalParams(
      filtreListe: Array<FiltreDTO>,
      typeFiltre: FiltreType
    ): Array<Filtre> {
      return filtreListe.map((filtreItem) => {
        return {
          ...filtreItem,
          estActif: false,
          type: typeFiltre,
        };
      });
    },
    sortedArray(array: Array<Filtre>) {
      // eslint-disable-next-line
      array.sort(function (a: any, b: any) {
        return a.nom.localeCompare(b.nom);
      });
      return array;
    },
    setFilters() {
      const {
        typeProductions,
        typeClienteles,
        produits,
        volumes,
        conservations,
        //typeTransactions,
        specificites,
        certifications,
        conditionnements,
        typeContenants,
        dureeVies,
        codeBarres,
        quantiteMinimaleCommandes,
        disponibilites,
        permis,
        certificationInstallations,
      } = this.$store.getters['filtres/tousFiltres'];

      const trueConditionnements = this.getFiltreListeWithAddidionalParams(
        conditionnements,
        FiltreType.conditionnements
      );
      const trueTypeContenants = this.getFiltreListeWithAddidionalParams(
        typeContenants,
        FiltreType.typeContenants
      );
      const trueDureeVies = this.getFiltreListeWithAddidionalParams(
        dureeVies,
        FiltreType.dureeVies
      );
      const trueCodeBarres = this.getFiltreListeWithAddidionalParams(
        codeBarres,
        FiltreType.codeBarres
      );
      const trueQuantiteMinimaleCommandes =
        this.getFiltreListeWithAddidionalParams(
          quantiteMinimaleCommandes,
          FiltreType.quantiteMinimaleCommandes
        );
      const trueDisponibilites = this.getFiltreListeWithAddidionalParams(
        disponibilites,
        FiltreType.disponibilites
      );
      const truePermis = this.getFiltreListeWithAddidionalParams(
        permis,
        FiltreType.permis
      );
      const trueCertificationInstallations =
        this.getFiltreListeWithAddidionalParams(
          certificationInstallations,
          FiltreType.certificationInstallations
        );

      const trueTypeProductions = this.getFiltreListeWithAddidionalParams(
        typeProductions,
        FiltreType.typeProduction
      );
      // trueTypeProductions = this.sortedArray(trueTypeProductions);
      const trueTypeClienteles = this.getFiltreListeWithAddidionalParams(
        typeClienteles,
        FiltreType.typeClientelle
      );
      const trueProduits = this.getFiltreListeWithAddidionalParams(
        produits,
        FiltreType.produit
      );
      const trueVolume = this.getFiltreListeWithAddidionalParams(
        volumes,
        FiltreType.volume
      );
      const trueConservations = this.getFiltreListeWithAddidionalParams(
        conservations,
        FiltreType.conservation
      );
      // const trueTypeTransactions = this.getFiltreListeWithAddidionalParams(
      //   typeTransactions,
      //   FiltreType.typeTransaction
      // );
      const trueSpecificites = this.getFiltreListeWithAddidionalParams(
        specificites,
        FiltreType.specificite
      );
      const trueCertifications = this.getFiltreListeWithAddidionalParams(
        certifications,
        FiltreType.certification
      );
      this.filtres.typeProductions.data = this.sortedArray(trueTypeProductions);
      this.filtres.typeClienteles.data = this.sortedArray(trueTypeClienteles);
      this.filtres.produits.data = this.sortedArray(trueProduits);
      this.filtres.conservations.data = this.sortedArray(trueConservations);
      this.filtres.volumes.data = this.sortedArray(trueVolume);
      // this.filtres.typesTransactions.data =
      //   this.sortedArray(trueTypeTransactions);
      this.filtres.specificites.data = this.sortedArray(trueSpecificites);
      this.filtres.certifications.data = this.sortedArray(trueCertifications);

      this.filtres.conditionnements.data =
        this.sortedArray(trueConditionnements);
      this.filtres.typeContenants.data = this.sortedArray(trueTypeContenants);
      this.filtres.dureeVies.data = this.sortedArray(trueDureeVies);
      this.filtres.codeBarres.data = this.sortedArray(trueCodeBarres);
      this.filtres.quantiteMinimaleCommandes.data = this.sortedArray(
        trueQuantiteMinimaleCommandes
      );
      this.filtres.disponibilites.data = this.sortedArray(trueDisponibilites);
      this.filtres.permis.data = this.sortedArray(truePermis);
      this.filtres.certificationInstallations.data = this.sortedArray(
        trueCertificationInstallations
      );

      trueTypeProductions
        .concat(trueTypeClienteles)
        .concat(trueProduits)
        .concat(trueConservations)
        .concat(trueVolume)
        //.concat(trueTypeTransactions)
        .concat(trueSpecificites)
        .concat(trueCertifications)
        .concat(trueConditionnements)
        .concat(trueTypeContenants)
        .concat(trueDureeVies)
        .concat(trueCodeBarres)
        .concat(trueQuantiteMinimaleCommandes)
        .concat(trueDisponibilites)
        .concat(truePermis)
        .concat(trueCertificationInstallations)
        .forEach((filtre) => {
          const filtreKey = filtre.type + '-' + filtre.id;
          this.filtresRef.set(filtreKey, filtre);
        });
      EventSynchronisationHandler.invoke(EventSynchronisationName.filtresPret);
    },
  },
  mounted() {
    Promise.all([
      this.$store.dispatch('filtres/fetchTypeProduction'),
      this.$store.dispatch('filtres/fetchTypeClientele'),
      this.$store.dispatch('filtres/fetchProduit'),
      this.$store.dispatch('filtres/fetchConservation'),
      this.$store.dispatch('filtres/fetchVolume'),
      //this.$store.dispatch('filtres/fetchTypeTransaction'),
      this.$store.dispatch('filtres/fetchSpecificite'),
      this.$store.dispatch('filtres/fetchCertification'),

      this.$store.dispatch('filtres/fetchConditionnement'),
      this.$store.dispatch('filtres/fetchTypeContenant'),
      this.$store.dispatch('filtres/fetchDureeVie'),
      this.$store.dispatch('filtres/fetchCodeBarre'),
      this.$store.dispatch('filtres/fetchQuantiteMinimaleCommande'),
      this.$store.dispatch('filtres/fetchDisponibilite'),
      this.$store.dispatch('filtres/fetchPermis'),
      this.$store.dispatch('filtres/fetchCertificationInstallation'),
    ]).then(() => {
      this.setFilters();
    });
  },
});
</script>

<style scoped lang="scss">
@import '../assets/scss/_colors.scss';
@import '../assets/scss/_fonts.scss';

aside {
  height: 100%;
  margin-top: 25px;
  // la largeur de 256 pixels correspond à celle du panneau latéral pour le site de Best Buy
  // https://www.bestbuy.ca/fr-ca/collection/nintendo-preorder/65061?path=category%253AJeux%2Bvid%25C3%25A9o%253Bcategory%253ANintendo%253Bcategory%253ANintendo%2BSwitch%253Bcategory%253AJeux%2Bpour%2BNintendo%2BSwitch%253Bstatus0enrchstring%253APr%25C3%25A9commandez
  //width: 256px; cause le problème de border

  & .filtres {
    line-height: 25px;
    margin-top: 5px;
    text-align: left;
  }
}

.cache-cache {
  display: none;
}

.chevron {
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  font-size: 18px;
  height: 18px;
  width: 18px;

  &:hover {
    cursor: pointer;
  }
}

.chevron-down {
  @extend .chevron;

  background-image: url('../assets/img/angle-down-solid.svg');
}

.chevron-up {
  @extend .chevron;

  background-image: url('../assets/img/angle-up-solid.svg');
}

// pour que chaque section à cacher ne soit jamais collée sur les séparateurs quand elle est visiblie
.conteneur-cases {
  margin-bottom: 25px;
}

.pr25 {
  padding-right: 25px;
}

.separateur {
  border-bottom: 2px solid $grisSeparateurs;
  margin-bottom: 25px;
  // pour créer un léger retrait à droite du texte par rapport à la ligne grise de séparation
  padding-left: 30px;
  .titre-principal {
    &:hover {
      cursor: pointer;
      h2 {
        color: $bleuPale;
      }
    }
  }
}

.titre-principal {
  margin-bottom: 25px;

  // flex
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    color: $noirsInfosEntreprise;
    font-family: $roboto700;
    font-size: 14px;
  }
}
.libelle {
  margin-left: 10px;
  margin-right: 5px;
}
.hide {
  display: none;
}
</style>
