import { FiltreDTO } from '@/types/FiltreDTO';
import axios from 'axios';

type StateFiltre = {
  typeProductions: Array<FiltreDTO>;
  typeClienteles: Array<FiltreDTO>;
  produits: Array<FiltreDTO>;
  volumes: Array<FiltreDTO>;
  typesEntreprise: Array<FiltreDTO>;
  conservations: Array<FiltreDTO>;
  typeTransactions: Array<FiltreDTO>;
  certifications: Array<FiltreDTO>;
  specificites: Array<FiltreDTO>;
  conditionnements: Array<FiltreDTO>;
  typeContenants: Array<FiltreDTO>;
  dureeVies: Array<FiltreDTO>;
  codeBarres: Array<FiltreDTO>;
  quantiteMinimaleCommandes: Array<FiltreDTO>;
  disponibilites: Array<FiltreDTO>;
  permis: Array<FiltreDTO>;
  certificationInstallations: Array<FiltreDTO>;
};

const filtres = {
  namespaced: true,
  state: {
    typeProductions: Array<FiltreDTO>(),
    typeClienteles: Array<FiltreDTO>(),
    produits: Array<FiltreDTO>(),
    volumes: Array<FiltreDTO>(),
    typesEntreprise: Array<FiltreDTO>(),
    conservations: Array<FiltreDTO>(),
    typeTransactions: Array<FiltreDTO>(),
    certifications: Array<FiltreDTO>(),
    specificites: Array<FiltreDTO>(),
    conditionnements: Array<FiltreDTO>(),
    typeContenants: Array<FiltreDTO>(),
    dureeVies: Array<FiltreDTO>(),
    codeBarres: Array<FiltreDTO>(),
    quantiteMinimaleCommandes: Array<FiltreDTO>(),
    disponibilites: Array<FiltreDTO>(),
    permis: Array<FiltreDTO>(),
    certificationInstallations: Array<FiltreDTO>(),
  },
  getters: {
    typeProductions: (state: StateFiltre) => state.typeProductions,
    typeClienteles: (state: StateFiltre) => state.typeClienteles,
    produits: (state: StateFiltre) => state.produits,
    volumes: (state: StateFiltre) => state.volumes,
    typesEntreprise: (state: StateFiltre) => state.typesEntreprise,
    conservation: (state: StateFiltre) => state.conservations,
    typeTransactions: (state: StateFiltre) => state.typeTransactions,
    certifications: (state: StateFiltre) => state.certifications,
    specificites: (state: StateFiltre) => state.specificites,
    tousFiltres: (state: StateFiltre) => {
      return state;
    },
  },
  actions: {
    fetchTypeProduction({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/types-production`;
        axios
          .get(url)
          .then(function (response) {
            commit('setTypeProductions', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    fetchTypeClientele({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/types-clientele`;
        axios
          .get(url)
          .then(function (response) {
            commit('setTypeClienteles', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    fetchProduit({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/produits`;
        axios
          .get(url)
          .then(function (response) {
            commit('setProduits', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    fetchConservation({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/conservations`;
        axios
          .get(url)
          .then(function (response) {
            commit('setConservation', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    fetchVolume({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/volumes`;
        axios
          .get(url)
          .then(function (response) {
            commit('setVolume', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    fetchTypeTransaction({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/types-transactions`;
        axios
          .get(url)
          .then(function (response) {
            commit('setTypeTransaction', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    fetchSpecificite({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/specificites`;
        axios
          .get(url)
          .then(function (response) {
            commit('setSpecificite', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    fetchCertification({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/certifications`;
        axios
          .get(url)
          .then(function (response) {
            commit('setCertification', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    fetchConditionnement({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/conditionnements`;
        axios
          .get(url)
          .then(function (response) {
            commit('setConditionnement', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    fetchTypeContenant({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/type-contenants`;
        axios
          .get(url)
          .then(function (response) {
            commit('setTypeContenant', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    fetchDureeVie({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/duree-vies`;
        axios
          .get(url)
          .then(function (response) {
            commit('setDureeVie', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    fetchCodeBarre({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/code-barres`;
        axios
          .get(url)
          .then(function (response) {
            commit('setCodeBarre', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    fetchQuantiteMinimaleCommande({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/quantite-minimale-commandes`;
        axios
          .get(url)
          .then(function (response) {
            commit('setQuantiteMinimaleCommande', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    fetchDisponibilite({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/disponibilites`;
        axios
          .get(url)
          .then(function (response) {
            commit('setDisponibilite', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    fetchPermis({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/permis`;
        axios
          .get(url)
          .then(function (response) {
            commit('setPermis', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    fetchCertificationInstallation({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BACKEND_API_URL}api/certifications-installations`;
        axios
          .get(url)
          .then(function (response) {
            commit('setCertificationInstallation', response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
  },
  mutations: {
    setTypeProductions(state: StateFiltre, typeProductions: Array<FiltreDTO>) {
      state.typeProductions = typeProductions;
    },
    setTypeClienteles(state: StateFiltre, typeClienteles: Array<FiltreDTO>) {
      state.typeClienteles = typeClienteles;
    },
    setProduits(state: StateFiltre, produits: Array<FiltreDTO>) {
      state.produits = produits;
    },
    setVolume(state: StateFiltre, volumes: Array<FiltreDTO>) {
      state.volumes = volumes;
    },
    setTypeProduit(state: StateFiltre, typesEntreprise: Array<FiltreDTO>) {
      state.typesEntreprise = typesEntreprise;
    },
    setConservation(state: StateFiltre, conservations: Array<FiltreDTO>) {
      state.conservations = conservations;
    },
    setTypeTransaction(state: StateFiltre, typeTransactions: Array<FiltreDTO>) {
      state.typeTransactions = typeTransactions;
    },
    setSpecificite(state: StateFiltre, specificites: Array<FiltreDTO>) {
      state.specificites = specificites;
    },
    setCertification(state: StateFiltre, certifications: Array<FiltreDTO>) {
      state.certifications = certifications;
    },
    setConditionnement(state: StateFiltre, conditionnements: Array<FiltreDTO>) {
      state.conditionnements = conditionnements;
    },
    setTypeContenant(state: StateFiltre, typeContenants: Array<FiltreDTO>) {
      state.typeContenants = typeContenants;
    },
    setDureeVie(state: StateFiltre, dureeVies: Array<FiltreDTO>) {
      state.dureeVies = dureeVies;
    },
    setCodeBarre(state: StateFiltre, codeBarres: Array<FiltreDTO>) {
      state.codeBarres = codeBarres;
    },
    setQuantiteMinimaleCommande(
      state: StateFiltre,
      quantiteMinimaleCommandes: Array<FiltreDTO>
    ) {
      state.quantiteMinimaleCommandes = quantiteMinimaleCommandes;
    },
    setDisponibilite(state: StateFiltre, disponibilites: Array<FiltreDTO>) {
      state.disponibilites = disponibilites;
    },
    setPermis(state: StateFiltre, permis: Array<FiltreDTO>) {
      state.permis = permis;
    },
    setCertificationInstallation(
      state: StateFiltre,
      certificationInstallations: Array<FiltreDTO>
    ) {
      state.certificationInstallations = certificationInstallations;
    },
  },
};

export default filtres;
