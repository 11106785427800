<template>
  <div>
    <div v-for="section in sections" :key="section.index">
      <div class="accordion" @click="toggleSection(section.index)">
        <p>{{ section.name }}</p>
        <div class="accordion-right">
          <label v-if="section.index != 1" class="switch">
            <input type="checkbox" v-model="preferences.data[section.value]" />
            <span class="slider round"></span>
          </label>
          <label v-else class="fonctionnel-label"> Toujours activé</label>
          <i v-if="activeSection == section.index" class="arrow down"></i>
          <i v-else class="arrow up"></i>
        </div>
      </div>
      <div v-if="activeSection == section.index" class="panel">
        <p>{{ section.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:preferences'],
  data() {
    return {
      sections: [
        {
          index: 1,
          name: 'Fonctionnel',
          value: 'fonctionnel',
          description:
            'Le stockage ou l’accès technique est strictement nécessaire dans la finalité d’intérêt légitime de permettre l’utilisation d’un service spécifique explicitement demandé par l’abonné ou l’utilisateur, ou dans le seul but d’effectuer la transmission d’une communication sur un réseau de communications électroniques.',
        },
        {
          index: 2,
          name: 'Préférences',
          value: 'preferences',
          description:
            'Le stockage ou l’accès technique est nécessaire dans la finalité d’intérêt légitime de stocker des préférences qui ne sont pas demandées par l’abonné ou l’utilisateur.',
        },
        {
          index: 3,
          name: 'Statistiques',
          value: 'statistics',
          description:
            'Le stockage ou l’accès technique qui est utilisé exclusivement à des fins statistiques.',
        },
        {
          index: 4,
          name: 'Marketing',
          value: 'marketing',
          description:
            'Le stockage ou l’accès technique est nécessaire pour créer des profils d’utilisateurs afin d’envoyer des publicités, ou pour suivre l’utilisateur sur un site web ou sur plusieurs sites web ayant des finalités marketing similaires.',
        },
      ],
      activeSection: 0,
      preferences: {
        data: {
          fonctionnel: true,
          preferences: true,
          statistics: true,
          marketing: true,
        },
      },
    };
  },
  watch: {
    'preferences.data': {
      handler: function (after, before) {
        this.$emit('update:preferences', after);
      },
      deep: true,
    },
  },
  methods: {
    toggleSection(index) {
      if (this.activeSection == index) {
        this.activeSection = 0;
      } else {
        this.activeSection = index;
      }
    },
  },
  mounted() {
    var preferences = JSON.parse(localStorage.getItem('preferences'));
    this.preferences.data.preferences = preferences.preferences;
    this.preferences.data.statistics = preferences.statistics;
    this.preferences.data.marketing = preferences.marketing;
  },
};
</script>

<style scoped>
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
}
.up {
  margin-left: 1rem;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  margin-left: 1rem;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  /* width: 100%; */
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion p {
  font-size: 15px;
  font-weight: 600;
}
.fonctionnel-label {
  color: green;
}
/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion:hover {
  background-color: #acacac;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  overflow: hidden;
}

.panel p {
  margin: 1rem 0rem 1rem 0rem;
  font-size: 14px;
}

/* TOGGLE /
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: green;
}

input:focus + .slider {
  box-shadow: 0 0 1px green;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
