<template>
  <head>
    <title>Inscription | RACA</title>
  </head>
  <div class="content">
    <div class="box">
      <section id="section-header" class="main-section">
        <router-link :to="'/'" title="Page d'accueil RACA" class="flex-gauche">
          <img
            src="../assets/img/repertoire-agroalimentaire-chaudiere-appalaches_logo.svg"
            alt="Logo Répertoire agroalimentaire Chaudière-Appalaches (RACA)"
          />
        </router-link>
        <h2>Demande d’inscription</h2>
      </section>
      <section id="section-infos-personnelles" class="main-section">
        <h3 class="titre-section">Informations personnelles</h3>
        <section class="inner-section">
          <div class="input-div">
            <label for="input-nom" class="form-label">Nom</label>
            <input type="text" id="input-nom" v-model="inscriptionData.nom" />
            <div
              class="errorMessage"
              v-for="error of v$.inscriptionData.nom.$errors"
              :key="error.$uid"
            >
              <label v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </label>
              <label v-if="error.$validator == 'minLength'">
                Ce champ doit être au moins {{ error.$params.min }} caractères.
              </label>
              <label v-if="error.$validator == 'maxLength'">
                Ce champ ne doit pas excéder
                {{ error.$params.max }} caractères.
              </label>
            </div>
          </div>
          <div class="input-div">
            <label for="input-prenom" class="form-label">Prénom</label>
            <input
              type="text"
              id="input-prenom"
              v-model="inscriptionData.prenom"
            />
            <div
              class="errorMessage"
              v-for="error of v$.inscriptionData.prenom.$errors"
              :key="error.$uid"
            >
              <label v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </label>
              <label v-if="error.$validator == 'minLength'">
                Ce champ doit être au moins {{ error.$params.min }} caractères.
              </label>
              <label v-if="error.$validator == 'maxLength'">
                Ce champ ne doit pas excéder
                {{ error.$params.max }} caractères.
              </label>
            </div>
          </div>
          <div class="input-div">
            <label for="input-email">Adresse courriel</label>
            <input
              type="text"
              id="input-email"
              v-model="inscriptionData.email"
            />
            <div
              class="errorMessage"
              v-for="error of v$.inscriptionData.email.$errors"
              :key="error.$uid"
            >
              <label v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </label>
              <label v-if="error.$validator == 'email'">
                Adresse courriel incorrect
              </label>
              <label v-if="error.$validator == 'maxLength'">
                Ce champ ne doit pas excéder
                {{ error.$params.max }} caractères.
              </label>
            </div>
          </div>
        </section>
      </section>
      <section id="section-infos-entreprise" class="main-section">
        <h3 class="titre-section">Informations sur l'entreprise</h3>
        <section id="section-infos-generales" class="inner-section">
          <h4>Informations générales</h4>
          <div class="input-div">
            <label for="input-nomEntreprise">Nom de l'entreprise</label>
            <input
              type="text"
              id="input-nomEntreprise"
              v-model="inscriptionData.nomEntreprise"
            />
            <div
              class="errorMessage"
              v-for="error of v$.inscriptionData.nomEntreprise.$errors"
              :key="error.$uid"
            >
              <label v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </label>
              <label v-if="error.$validator == 'minLength'">
                Ce champ doit être au moins {{ error.$params.min }} caractères.
              </label>
              <label v-if="error.$validator == 'maxLength'">
                Ce champ ne doit pas excéder
                {{ error.$params.max }} caractères.
              </label>
            </div>
          </div>
          <div class="input-div">
            <label for="input-courrielEntreprise"
              >Adresse courriel de votre entreprise</label
            >
            <input
              type="text"
              id="input-courrielEntreprise"
              v-model="inscriptionData.emailEntreprise"
            />
            <div
              class="errorMessage"
              v-for="error of v$.inscriptionData.emailEntreprise.$errors"
              :key="error.$uid"
            >
              <label v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </label>
              <label v-if="error.$validator == 'email'">
                Adresse courriel incorrect
              </label>
              <label v-if="error.$validator == 'maxLength'">
                Ce champ ne doit pas excéder
                {{ error.$params.max }} caractères.
              </label>
            </div>
          </div>
          <div class="input-div">
            <label for="input-siteWeb"
              >Site web <span class="optional">(facultatif)</span></label
            >
            <input
              type="text"
              id="input-siteWeb"
              v-model="inscriptionData.siteWeb"
              v-maska="urlMask"
            />
          </div>
          <!-- <div
            class="errorMessage"
            v-for="error of v$.inscriptionData.siteWeb.$errors"
            :key="error.$uid"
          >
            <label v-if="error.$validator == 'maxLength'">
              Ce champ ne doit pas excéder
              {{ error.$params.max }} caractères.
            </label>
            <label v-if="error.$validator == 'url'">
              {{ wrongUrlFormat }}
            </label>
          </div> -->

          <div class="input-div">
            <label
              >Réseaux sociaux <span class="optional">(facultatif)</span></label
            >
            <p>
              Veuillez inscrire l'URL complète.
              <span id="exempleURL"> (Exemple : repertoireagro.ca)</span>
            </p>
            <div class="reseauxSo">
              <font-awesome-icon :icon="['fab', 'facebook']" />
              <input
                type="text"
                v-model="inscriptionData.facebook"
                v-maska="urlMask"
                placeholder="https://www.facebook.com/votretaca"
              />
            </div>
            <!-- <div
              class="errorMessage"
              v-for="error of v$.inscriptionData.facebook.$errors"
              :key="error.$uid"
            >
              <label v-if="error.$validator == 'maxLength'">
                Ce champ ne doit pas excéder
                {{ error.$params.max }} caractères.
              </label>
              <label v-if="error.$validator == 'url'">
                {{ wrongUrlFormat }}
              </label>
            </div> -->
            <div class="reseauxSo">
              <font-awesome-icon :icon="['fab', 'instagram']" />
              <input
                type="text"
                v-model="inscriptionData.instagram"
                v-maska="urlMask"
              />
            </div>
            <!-- <div
              class="errorMessage"
              v-for="error of v$.inscriptionData.instagram.$errors"
              :key="error.$uid"
            >
              <label v-if="error.$validator == 'maxLength'">
                Ce champ ne doit pas excéder {{ error.$params.max }} caractères.
              </label>
              <label v-if="error.$validator == 'url'">
                {{ wrongUrlFormat }}
              </label>
            </div> -->
            <div class="reseauxSo">
              <font-awesome-icon :icon="['fab', 'twitter']" />
              <input
                type="text"
                v-model="inscriptionData.twitter"
                v-maska="urlMask"
              />
            </div>
            <!-- <div
              class="errorMessage"
              v-for="error of v$.inscriptionData.twitter.$errors"
              :key="error.$uid"
            >
              <label v-if="error.$validator == 'maxLength'">
                Ce champ ne doit pas excéder
                {{ error.$params.max }} caractères.
              </label>
              <label v-if="error.$validator == 'url'">
                {{ wrongUrlFormat }}
              </label>
            </div> -->
          </div>
        </section>
        <section id="section-adresse" class="inner-section">
          <h4>Adresse</h4>
          <div class="input-div">
            <label for="adresse">Adresse civique</label>
            <input
              type="text"
              id="adresse"
              v-model="inscriptionData.adresseProd"
            />
            <div
              class="errorMessage"
              v-for="error of v$.inscriptionData.adresseProd.$errors"
              :key="error.$uid"
            >
              <label v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </label>
              <label v-if="error.$validator == 'maxLength'">
                Ce champ ne doit pas excéder {{ error.$params.max }} caractères.
              </label>
            </div>
          </div>
          <div class="input-div">
            <label>MRC</label>
            <select @change="onChange" v-model="inscriptionData.mrc_id">
              <option></option>
              <option v-for="mrc in mrcs" :key="mrc.id" :value="mrc.id">
                {{ mrc.nom }}
              </option>
            </select>
            <div
              class="errorMessage"
              v-for="error of v$.inscriptionData.mrc_id.$errors"
              :key="error.$uid"
            >
              <label v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </label>
            </div>
          </div>
          <div id="input-div-flex">
            <div id="input-div-ville" class="input-div">
              <label for="input-ville">Ville</label>
              <select id="input-ville" v-model="inscriptionData.ville_id">
                <option></option>
                <option
                  v-for="ville in villesMrc"
                  :key="ville.id"
                  :value="ville.id"
                >
                  {{ ville.nom }}
                </option>
              </select>
              <div
                class="errorMessage"
                v-for="error of v$.inscriptionData.ville_id.$errors"
                :key="error.$uid"
              >
                <label v-if="error.$validator == 'required'">
                  {{ requiredMessage }}
                </label>
              </div>
            </div>
            <div id="input-div-codePostal" class="input-div">
              <label for="input-codePostal">Code postal</label>
              <input
                type="text"
                id="input-codePostal"
                v-model="inscriptionData.codePostal"
                v-maska="'A#A #A#'"
              />
              <div
                class="errorMessage"
                v-for="error of v$.inscriptionData.codePostal.$errors"
                :key="error.$uid"
              >
                <label v-if="error.$validator == 'required'">
                  {{ requiredMessage }}
                </label>
                <label v-if="error.$validator == 'maxLength'">
                  Ce champ ne doit pas excéder
                  {{ error.$params.max }} caractères.
                </label>
                <label v-if="error.$validator == 'codePostal'">
                  {{ wrongFormat }}
                </label>
              </div>
            </div>
          </div>
        </section>
        <section id="section-consentement" class="inner-section">
          <h4>Consentements</h4>
          <div class="input-div">
            <div
              class="consentement"
              v-for="consentement in consentements"
              :key="consentement.id"
            >
              <input type="checkbox" v-model="consentement.selected" />
              <p>{{ consentement.nom }}</p>
            </div>
            <div class="errorMessage" v-if="errorCheckboxes">
              <label>
                {{ requiredMessageCheckbox }}
              </label>
            </div>
          </div>
        </section>
        <section id="form-end-section" class="inner-section">
          <button @click="submitform">Envoyer la demande</button>
          <p v-if="serverError" class="errorMessage">{{ serverMessage }}</p>
          <p>
            Vous avez déjà un compte ?
            <a :href="loginLink()" title="Lien de connexion">Connectez-vous</a>
          </p>
        </section>
      </section>
    </div>
    <div id="popup" class="popup">
      <div class="popup-content">
        <img src="@/assets/img/icone-checkmark.svg" alt="checkmark" />
        <h2>Inscription complétée</h2>
        <p>
          Vous recevrez un courriel avec le mot de passe qui a été envoyé à
          l'adresse courriel fournie.
        </p>
        <router-link :to="'/'" title="Page d'accueil RACA"
          >Retour à l'accueil</router-link
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { InscriptionData } from '@/types/Inscription/InscriptionData';
import { AxiosResponse } from 'axios';
import { InscriptionDTO } from '@/types/Inscription/InscriptionDTO';
import useVuelidate from '@vuelidate/core';
import {
  required,
  email,
  minLength,
  maxLength,
  helpers,
} from '@vuelidate/validators';
import { Consentement } from '@/types/Inscription/Consentement';
export default defineComponent({
  name: 'PageInscription',
  setup: () => ({ v$: useVuelidate() }),
  computed: {
    urlRules() {
      return helpers.regex(/^https:\/\/[-_0-9a-zA-Z]+\.[0-9a-zA-Z]+$/);
    },
    urlMask() {
      return {
        mask: 'https://H*',
        tokens: { H: { pattern: /[0-9a-zA-Z_.-/]+/ } },
      };
    },
  },
  data() {
    return {
      mrcs: new Array<InscriptionData>(),
      villes: new Array<InscriptionData>(),
      villesMrc: new Array<InscriptionData>(),
      consentements: new Array<Consentement>(),
      inscriptionData: {} as InscriptionDTO,
      requiredMessage: 'Champ requis.',
      requiredMessageCheckbox: 'Au moins un champ doit être coché',
      wrongFormat: 'Format incorrect. Format: A1A 1A1',
      wrongUrlFormat: 'Format incorrect.',
      serverError: false,
      serverMessage: '',
      errorCheckboxes: false,
    };
  },
  validations() {
    return {
      inscriptionData: {
        nom: { required, minLength: minLength(3), maxLength: maxLength(40) },
        prenom: { required, minLength: minLength(3), maxLength: maxLength(40) },
        email: { required, email, maxLength: maxLength(80) },
        nomEntreprise: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        emailEntreprise: { required, email, maxLength: maxLength(80) },
        adresseProd: { required, maxLength: maxLength(255) },
        mrc_id: { required },
        ville_id: { required },
        // siteWeb: { maxLength: maxLength(255), url: this.urlRules },
        // facebook: { maxLength: maxLength(255), url: this.urlRules },
        // instagram: { maxLength: maxLength(255), url: this.urlRules },
        // twitter: { maxLength: maxLength(255), url: this.urlRules },
        codePostal: {
          required,
          codePostal: helpers.regex(
            /^[a-zA-Z]{1}\d{1}[a-zA-Z]{1}\s\d{1}[a-zA-Z]{1}\d{1}$/
          ),
        },
      },
    };
  },
  methods: {
    openPopup() {
      document.getElementById('popup').style.display = 'block';
    },
    closePopup() {
      document.getElementById('popup').style.display = 'none';
      this.$router.push({ name: 'PageEntreprises' });
    },
    submitform() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.register();
    },
    register() {
      this.inscriptionData.consentements = this.consentements.filter(
        (item) => item.selected
      );
      this.errorCheckboxes = this.inscriptionData.consentements.length <= 0;
      if (!this.errorCheckboxes) {
        this.$store
          .dispatch('inscription/demandeInscription', this.inscriptionData)
          .then(() => {
            this.openPopup();
          })
          .catch((message) => {
            this.serverError = true;
            this.$toast.error(message);
            this.serverMessage = message;
          });
      }
    },
    loginLink(): string {
      return process.env.VUE_APP_BACKEND_API_URL + 'login';
    },
    onChange() {
      this.villesMrc = this.villes.filter(
        (item) => item.mrc_id === this.inscriptionData.mrc_id
      );
    },
  },
  mounted() {
    this.$store
      .dispatch('inscription/getMrcs')
      .then((response: AxiosResponse) => {
        this.mrcs = response;
      });
    this.$store
      .dispatch('inscription/getVilles')
      .then((response: Array<InscriptionData>) => {
        this.villes = response;
      });
    this.$store
      .dispatch('inscription/getConsentements')
      .then((response: Array<InscriptionData>) => {
        this.consentements = response.map((item) => {
          return { ...item, selected: true };
        });
      });
  },
});
</script>

<style scoped lang="scss">
@import '../assets/scss/_colors.scss';
.content {
  position: relative;
  background-color: $bleuTresPale;
  background-image: url('../assets/img/gerbes.svg');
  background-repeat: repeat-x;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 50px;
  .box {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 60%;
    margin: auto;
    @media screen and (max-width: 768px) {
      width: 85%;
    }
    h2 {
      font-weight: 400;
      margin-top: 18px;
      font-size: 20px;
      margin-bottom: 24px;
      color: $vert;
    }
    p {
      margin-top: 20px;
      font-weight: bold;
      a {
        color: $bleuFonce;
      }
    }
    .input-div {
      margin-bottom: 15px;
      input,
      select {
        height: 37px;
        border: 1px solid $grisSeparateurs;
        border-radius: 5px;
        font-size: 15px;
        margin-top: 10px;
        width: 100%;
      }
      input:focus,
      select:focus {
        outline: none;
      }
      p {
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 14px;
      }
    }
    button {
      margin-top: 15px;
      width: 100%;
      background-color: $vert;
      border: none;
      color: #fff;
      border-radius: 5px;
      height: 37px;
      font-weight: bold;
    }
  }
  .consentement {
    display: flex;
    align-items: center;
    input {
      height: 20px !important;
      flex-basis: 80px;
    }
    p {
      flex-basis: 100%;
    }
  }
  .popup {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }

  .popup-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 25%;
    font-weight: 400;
    font-size: 1.5vh;
    color: $noirCaracteresAdresses;
    @media screen and (max-width: 1024px) {
      width: 50%;
    }
  }
  h2 {
    margin: 20px 0;
    color: $vert;
    font-size: 2rem;
    font-weight: 700;
  }
  a {
    margin: 20px 0;
    color: $bleuFonce;
    font-weight: 400;
    font-size: 1.4rem;
    text-decoration: none;
  }
  p {
    margin: 20px 0;
  }

  .close {
    margin-top: 1%;
    background-color: $vert;
    border: none;
    color: #fff;
    border-radius: 5px;
    height: 37px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}
.main-section {
  padding: 40px;
  .titre-section {
    font-weight: 500;
    color: $bleuFonce;
    font-size: 14pt;
    text-align: left;
    margin-bottom: 25px;
  }
  label {
    text-align: left;
  }
}
#section-header,
#section-infos-personnelles {
  border-bottom: 1px solid $grisSeparateurs;
}
#section-header {
  text-align: left;
  padding-bottom: 0;
}
#section-header h2 {
  font-size: 20px;
  font-weight: 700;
}
.inner-section {
  width: 50%;
  margin-left: auto;
  margin-bottom: 40px;
  display: block;
  text-align: left;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  h4 {
    color: $vert;
    font-size: 14pt;
    text-align: left;
    margin-bottom: 15px;
    font-weight: 500;
  }
  label {
    font-weight: 400;
    font-size: 14px;
    color: $noirsInfosEntreprise;
    margin-bottom: 10px;
  }
  .errorMessage label {
    text-align: left;
    margin-top: 10px;
    color: red;
    font-size: 15px;
  }
}
.optional {
  color: $bleuPale;
}
.reseauxSo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  input {
    width: 90% !important;
    &:not(:first) {
      margin-top: 0;
    }
  }
  svg {
    align-self: center;
  }
}
#form-end-section {
  p {
    font-size: 14px;
    font-weight: 400;
  }
  a {
    font-weight: 400;
    font-size: 14px;
  }
}
#input-div-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#input-div-ville {
  width: 55%;
}
#input-div-codePostal {
  width: 35%;
}
#exempleURL {
  color: #888;
}
</style>
