<template>
  <head>
    <title>Recherche | RACA</title>
  </head>
  <div id="recherche" class="grid-main-et-marges">
    <div class="marge"></div>
    <main>
      <div class="filtres" ref="panneauLateral">
        <FiltresEntreprises @filtreChangement="chargeListeEntreprises" />
      </div>

      <section v-if="entreprisesPretes">
        <div>
          <form
            class="conteneur-zone-rechercher"
            @submit.prevent="faisRecherche"
          >
            <input type="search" v-model="recherche" />
            <div class="conteneur-bouton-recherche">
              <button class="bouton-recherche">Rechercher</button>
            </div>
          </form>
        </div>

        <div class="conteneur-sous-barre-recherche">
          <div>
            {{
              totalResultat === 0
                ? 'Aucun résultat à afficher'
                : `${totalResultat} résultat(s)`
            }}
          </div>
        </div>
        <div class="conteneur-etiquettes">
          <template :key="id" v-for="[id, filtre] in filtres">
            <div class="etiquette" :ref="filtre.nom" v-if="filtre.estActif">
              <span class="libelle-etiquette">{{ filtre.nom }}</span>
              <span class="fermeture-etiquette" @click="removeFiltre(filtre)"
                >x</span
              >
            </div>
          </template>
          <button
            @click="reinitialiseFiltres"
            class="bouton-reinitialisation"
            v-show="getNbOfFiltresActif() > 0"
          >
            Effacer les filtres
          </button>
        </div>
        <div
          class="card"
          :key="entreprise.id"
          v-for="entreprise in listeEntreprisesAffiche"
        >
          <div class="carte-avec-marge-interieure">
            <h3>
              {{ entreprise.nom }}
              <!-- début bouton de modal voir plus -->
              <button
                class="show-more"
                @click="openModalEntreprise(entreprise)"
              >
                Afficher plus
              </button>
              <!-- fin bouton de modal voir plus -->
            </h3>

            <!-- début grille des coordonnées -->
            <div>
              <div class="grille-coordonnees">
                <div>
                  <h4>
                    {{ entreprise.nomContact }}
                  </h4>
                  <!-- Courriel -->
                  <div v-if="entreprise.courriel">
                    <font-awesome-icon :icon="['far', 'envelope']" />
                    <a class="site-web" :href="`mailto:${entreprise.courriel}`">
                      {{ entreprise.courriel }}
                    </a>
                  </div>

                  <!-- Lien Facebook -->
                  <div v-if="entreprise.facebook">
                    <font-awesome-icon :icon="['fab', 'facebook']" />
                    <a
                      :href="entreprise.facebook"
                      :title="`Page Facebook pour ${entreprise.nom}`"
                      >Lien vers Facebook</a
                    >
                  </div>
                  <!-- Fin lien Facebook -->

                  <!-- Lien Twitter -->
                  <div v-if="entreprise.twitter">
                    <font-awesome-icon :icon="['fab', 'twitter']" />
                    <a
                      :href="entreprise.twitter"
                      :title="`Twitter pour ${entreprise.nom}`"
                      >Lien vers Twitter</a
                    >
                  </div>
                  <!-- Fin lien Twitter -->

                  <!-- Lien Instagram -->
                  <div v-if="entreprise.instagram">
                    <font-awesome-icon :icon="['fab', 'instagram']" />
                    <a
                      :href="entreprise.instagram"
                      :title="`Instagram pour ${entreprise.nom}`"
                      >Lien vers Instagram</a
                    >
                  </div>
                  <!-- Fin lien Twitter -->

                  <!-- Lien entreprise -->
                  <div v-if="entreprise.siteWeb">
                    <font-awesome-icon :icon="['far', 'globe']" />
                    <a
                      class="site-web"
                      :href="entreprise.siteWeb"
                      :title="`Site pour ${entreprise.nom}`"
                      >{{ entreprise.siteWeb }}</a
                    >
                  </div>
                </div>
                <!-- Fin lien entreprise -->

                <div>
                  <div>{{ entreprise.adresse }}</div>
                  <div v-if="entreprise.ville">{{ entreprise.ville.nom }}</div>
                  <div>{{ entreprise.codePostal }}</div>
                </div>

                <!-- début troisième colonne -->
                <div>
                  <div>
                    <div
                      v-for="entreprise_produit in entreprise.entreprise_produits"
                      :key="entreprise_produit.id"
                    >
                      {{ entreprise_produit.produit.nom }}
                      <span
                        v-if="entreprise_produit.type_transaction"
                        :style="{
                          color: entreprise_produit.type_transaction.color
                            ? entreprise_produit.type_transaction.color
                            : 'inherit',
                        }"
                      >
                        ({{ entreprise_produit.type_transaction.nom }})</span
                      >
                    </div>
                  </div>
                </div>
                <!-- fin troisième colonne -->
              </div>
              <!-- fin grille des coordonnées -->
            </div>
          </div>
        </div>
        <!-- fin grille coordonnées -->

        <div class="conteneur-exportation-et-navigation">
          <div class="export-buttons">
            <JsonCSV
              :data="getListeEntreprisesToExport()"
              name="Entreprises.csv"
              delimiter=";"
            >
              <button
                class="exportation"
                v-show="listeEntreprisesAffiche.length > 0"
              >
                <font-awesome-icon :icon="['far', 'file-export']" />
                Exporter en CSV
              </button>
            </JsonCSV>
            <button class="exportation" @click="exportPDF()">
              <font-awesome-icon :icon="['far', 'file-export']" />
              Exporter en PDF
            </button>
          </div>
          <div class="nav-pagination" v-if="totalResultat !== 0">
            <font-awesome-icon
              :icon="['fas', 'caret-left']"
              class="logo-fleche"
              @click="previousPage"
              id="fleche-gauche"
              v-if="indexPage > 0"
            />
            Page {{ indexPage + 1 }} de {{ numberOfPages }}
            <font-awesome-icon
              :icon="['fas', 'caret-right']"
              class="logo-fleche"
              @click="nextPage"
              id="fleche-droite"
              v-if="indexPage < numberOfPages - 1"
            />
          </div>
        </div>
        <div>
          <ModalEntreprise
            v-show="showModal"
            @close="showModal = false"
            ref="modalEntreprise"
          />
        </div>
      </section>
    </main>
    <div class="marge"></div>
  </div>
  <FooterPartner></FooterPartner>
</template>

<script scoped lang="ts">
import { defineComponent, provide, ref } from 'vue';
import FiltresEntreprises from '@/components/FiltresEntreprises.vue';
import FooterPartner from '@/components/FooterPartner.vue';
import { Filtre } from '@/types/Filtre';
import { Entreprise } from '@/types/Entreprise';
import { FiltreType } from '@/types/FiltreType';
import JsonCSV from 'vue-json-csv';
import RequeteRechercheHandler from '@/handlers/RequeteRechercheHandler';
import EventSynchronisationHandler from '@/handlers/EventSynchronisationHandler';
import { EventSynchronisationName } from '@/types/EventSynchronisationName';
import { EntrepriseProduits } from '@/types/EntrepriseProduits';
import { FiltresRegroupements } from '../types/FiltresRegroupements';
import ModalEntreprise from '@/components/ModalEntreprise.vue';
import axios from 'axios';

export default defineComponent({
  name: 'ListeEntreprises',
  components: {
    FiltresEntreprises,
    FooterPartner,
    ModalEntreprise,
    JsonCSV,
  },
  data() {
    return {
      listeEntreprisesAffiche: new Array<Entreprise>(),
      listeEntrepriseCache: new Array<Entreprise>(),
      indexPage: 0,
      numberOfPages: 0,
      nbEntreprisesParPage: 4,
      recherche: '',
      totalResultat: 0,
      entreprisesPretes: false,
      showModal: false,
    };
  },
  setup() {
    const filtres = ref(new Map<string, Filtre>());
    provide('filtres', filtres);
    return {
      filtres,
    };
  },
  methods: {
    exportPDF() {
      let loader = this.$loading.show();
      // Récupérez les IDs des entreprises et triez-les
      var entreprisesIds = this.getListeEntreprisesToExport().map(
        (entreprise) => entreprise.id
      );
      entreprisesIds.sort((a, b) => a - b);

      const url = `${process.env.VUE_APP_BACKEND_API_URL}api/generatePdf`;

      // Ajoutez les IDs triés à la requête GET
      const queryParams = entreprisesIds.map((id) => `id[]=${id}`).join('&');

      // Construisez l'URL complète avec les paramètres de requête
      const fullUrl = `${url}?${queryParams}`;

      // Utilisez Axios pour effectuer une requête GET
      axios
        .get(fullUrl, { responseType: 'arraybuffer' })
        .then(function (response) {
          loader.hide();
          // Créez un objet Blob avec les données du PDF
          const blob = new Blob([response.data], { type: 'application/pdf' });

          // Créez un lien (anchor) pour le téléchargement du PDF
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Entreprises.pdf';

          // Ajoutez le lien à la page et déclenchez le téléchargement
          document.body.appendChild(link);
          link.click();

          // Nettoyez le lien après le téléchargement
          document.body.removeChild(link);
        })
        .catch(function (error) {
          // Gérez les erreurs
          console.error('Erreur lors du téléchargement du PDF:', error);
        });
    },

    openModalEntreprise(entreprise: Entreprise) {
      this.showModal = true;
      this.$refs.modalEntreprise.updateData(entreprise);
    },
    getFiltresActifsSelonEntrepriseOuProduit(): FiltresRegroupements {
      const filtresToReturn = new FiltresRegroupements();
      for (let filtre of this.filtres.values()) {
        if (filtre.estActif) {
          filtresToReturn.addFiltre(filtre);
        }
      }
      return filtresToReturn;
    },
    updateQueryParams() {
      this.$router.push({ query: { page: this.indexPage } });
    },
    nextPage() {
      if (this.indexPage < this.numberOfPages - 1) {
        this.indexPage++;
        this.updateQueryParams();
        this.chargeListeEntreprises();
      }
    },
    getNbOfFiltresActif() {
      let compteur = 0;
      for (let filter of this.filtres.values()) {
        if (filter.estActif) {
          compteur++;
        }
      }
      return compteur;
    },
    previousPage() {
      if (this.indexPage > 0) {
        this.indexPage--;
        this.updateQueryParams();
        this.chargeListeEntreprises();
      }
    },
    sortedArray(array: Array<Filtre>) {
      // eslint-disable-next-line
      array.sort(function (a: any, b: any) {
        return a.nom.localeCompare(b.nom);
      });
      return array;
    },
    chargeListeEntreprises() {
      // récupération de la liste des entreprises
      const toutesEntreprises = this.sortedArray(
        this.getListeEntreprisesWithFilters()
      );
      this.totalResultat = toutesEntreprises.length;
      this.listeEntreprisesAffiche =
        this.getEntrepriseListSelonPage(toutesEntreprises);
      this.numberOfPages = Math.ceil(toutesEntreprises.length / 4);
    },
    getListeEntreprisesWithFilters(): Array<Entreprise> {
      const listeEntreprises = this.listeEntrepriseCache;
      const filtresSelonType =
        this.getFiltresActifsSelonEntrepriseOuProduit() as FiltresRegroupements;

      // const filtresProduitsParTypes = new Map<FiltreType, Array<number>>();
      // filtresSelonType.filtresProduits.forEach((filtre) => {
      //   if (!filtresProduitsParTypes.has(filtre.type)) {
      //     filtresProduitsParTypes.set(filtre.type, new Array<number>());
      //   }
      //   filtresProduitsParTypes.get(filtre.type).push(filtre.id);
      // });
      const toutesEntreprises = listeEntreprises.filter((entreprise) => {
        return (
          this.entrepriseContienFiltre(entreprise, filtresSelonType) &&
          this.entrepriseContienRecherche(entreprise)
        );
      });
      return toutesEntreprises as Array<Entreprise>;
    },
    getFilterListAsString(filterList?: Array<Filtre>) {
      if (filterList) {
        return filterList.map((item) => item.nom).join(', ');
      }
      return null;
    },
    getListeEntreprisesToExport() {
      const entreprises = this.getListeEntreprisesWithFilters().map(
        (entreprise) => {
          const type_productions = this.getFilterListAsString(
            entreprise.type_productions
          );
          const type_clienteles = this.getFilterListAsString(
            entreprise.type_clienteles
          );
          const produits = this.getFilterListAsString(entreprise.produits);
          const newEntreprise = {
            ...entreprise,
            type_productions,
            type_clienteles,
            produits,
          };
          if (entreprise.ville) {
            newEntreprise.ville = entreprise.ville.nom;
          }
          return newEntreprise;
        }
      );
      return entreprises;
    },
    getEntrepriseListSelonPage(listeEntreprises: Array<Entreprise>) {
      const startIndex = this.indexPage * this.nbEntreprisesParPage;
      let endIndex = this.nbEntreprisesParPage;
      if (startIndex + endIndex >= listeEntreprises.length) {
        return listeEntreprises.slice(startIndex);
      } else {
        return listeEntreprises.slice(startIndex, startIndex + endIndex);
      }
    },
    checkIfFiltreHas(item: { id: number }, typeFiltre: FiltreType) {
      const filtreKey = typeFiltre + '-' + item.id;
      return this.filtres.get(filtreKey).estActif;
    },
    checkIfFiltreOfTypeHasActivatedItem(typeFiltre: FiltreType) {
      for (let filtre of this.filtres.values()) {
        if (filtre.type === typeFiltre && filtre.estActif) {
          return true;
        }
      }
      return false;
    },
    entrepriseContienFiltre(
      entreprise: Entreprise,
      filtresRegroupements: FiltresRegroupements
    ) {
      if (entreprise.entreprise_produits.length < 1) {
        return false;
      }
      const produitsValidesParId = new Map<number, Array<EntrepriseProduits>>();
      entreprise.entreprise_produits.forEach((produit_entreprise) => {
        let produitAUnFiltre =
          filtresRegroupements.filtresProduits.filtreOu.size === 0;
        for (let [filtreType, listFiltres] of filtresRegroupements
          .filtresProduits.filtreOu) {
          let produitFiltreId;
          let isArray = false;
          switch (filtreType) {
            case FiltreType.volume:
              produitFiltreId = produit_entreprise.volume_id;
              break;
            case FiltreType.produit:
              produitFiltreId = produit_entreprise.produit_id;
              break;
            case FiltreType.conservation:
              produitFiltreId = produit_entreprise.conservations;
              isArray = true;
              break;
            case FiltreType.specificite:
              produitFiltreId = produit_entreprise.specificites;
              isArray = true;
              break;
            case FiltreType.conditionnements:
              produitFiltreId = produit_entreprise.conditionnements;
              isArray = true;
              break;
            case FiltreType.typeContenants:
              produitFiltreId = produit_entreprise.type_contenants;
              isArray = true;
              break;
            case FiltreType.dureeVies:
              produitFiltreId = produit_entreprise.duree_vies;
              isArray = true;
              break;
            case FiltreType.codeBarres:
              produitFiltreId = produit_entreprise.code_barres;
              isArray = true;
              break;
            case FiltreType.quantiteMinimaleCommandes:
              produitFiltreId = produit_entreprise.quantite_minimale_commande;
              isArray = true;
              break;
            case FiltreType.disponibilites:
              produitFiltreId = produit_entreprise.disponibilites;
              isArray = true;
              break;
          }
          if (isArray) {
            if (
              listFiltres.some((filtre) =>
                produitFiltreId.some((item) => item.id === filtre.id)
              )
            ) {
              produitAUnFiltre = true;
            }
          } else {
            if (listFiltres.some((filtre) => filtre.id === produitFiltreId)) {
              produitAUnFiltre = true;
            }
          }
        }
        if (produitAUnFiltre) {
          if (!produitsValidesParId.has(produit_entreprise.produit_id)) {
            produitsValidesParId.set(
              produit_entreprise.produit_id,
              new Array<EntrepriseProduits>(produit_entreprise)
            );
          } else {
            produitsValidesParId
              .get(produit_entreprise.produit_id)
              .push(produit_entreprise);
          }
        }
      });
      let unProduitAToutLesFiltres = false;
      for (let [idProduit, produitsList] of produitsValidesParId) {
        if (
          filtresRegroupements.filtresProduits.filtreEt.size === 0 ||
          (filtresRegroupements.filtresProduits.filtreEt.has(
            FiltreType.typeTransaction
          ) &&
            filtresRegroupements.filtresProduits.filtreEt
              .get(FiltreType.typeTransaction)
              .every((filtre) =>
                produitsList.some(
                  (produit_entreprise) =>
                    produit_entreprise.type_transaction_id === filtre.id
                )
              ))
        ) {
          unProduitAToutLesFiltres = true;
          break;
        }
      }

      if (
        !unProduitAToutLesFiltres &&
        (filtresRegroupements.filtresProduits.filtreOu.size > 0 ||
          filtresRegroupements.filtresProduits.filtreEt.size > 0)
      ) {
        return false;
      }
      for (let [filtreType, filtres] of filtresRegroupements.filtresEntreprises
        .filtreOu) {
        let entrepriseFiltre;
        switch (filtreType) {
          case FiltreType.typeClientelle:
            entrepriseFiltre = entreprise.type_clienteles;
            break;
          case FiltreType.certification:
            entrepriseFiltre = entreprise.certifications;
            break;
          case FiltreType.permis:
            entrepriseFiltre = entreprise.permis;
            break;
          case FiltreType.certificationInstallations:
            entrepriseFiltre = entreprise.certifications_installations;
            break;
        }
        if (
          !entrepriseFiltre.some((item) =>
            filtres.some((fil) => fil.id === item.id)
          )
        ) {
          return false;
        }
      }
      for (let [filtreType, filtres] of filtresRegroupements.filtresEntreprises
        .filtreEt) {
        let entrepriseFiltre;
        switch (filtreType) {
          case FiltreType.typeProduction:
            entrepriseFiltre = entreprise.type_productions;
            break;
        }
        if (
          !filtres.every((fil) =>
            entrepriseFiltre.some((item) => fil.id == item.id)
          )
        ) {
          return false;
        }
      }
      return true;
    },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(
          this.getNormalizedString(this.recherche.toLowerCase())
        )
      );
    },
    entrepriseContienRecherche(entreprise: Entreprise) {
      return (
        this.recherche == '' ||
        this.getIfStringMatchSearch(entreprise.nom) ||
        this.getIfStringMatchSearch(entreprise.adresse) ||
        (entreprise.ville &&
          this.getIfStringMatchSearch(entreprise.ville.nom)) ||
        this.getIfStringMatchSearch(entreprise.codePostal) ||
        this.getIfStringMatchSearch(entreprise.courriel) ||
        this.getIfStringMatchSearch(entreprise.facebook) ||
        this.getIfStringMatchSearch(entreprise.instagram) ||
        this.getIfStringMatchSearch(entreprise.siteWeb) ||
        this.getIfStringMatchSearch(entreprise.twitter) ||
        (entreprise.entreprise_produits !== undefined &&
          entreprise.entreprise_produits.some(
            (prod) =>
              this.getIfStringMatchSearch(prod.produit.nom) ||
              (prod.conservations !== undefined &&
                prod.conservations.some((item) =>
                  this.getIfStringMatchSearch(item.nom)
                )) ||
              (prod.specificites !== undefined &&
                prod.specificites.some((item) =>
                  this.getIfStringMatchSearch(item.nom)
                )) ||
              (prod.type_transaction !== undefined &&
                this.getIfStringMatchSearch(prod.type_transaction.nom))
          )) ||
        entreprise.type_clienteles.some((typeClientelle) =>
          this.getIfStringMatchSearch(typeClientelle.nom)
        ) ||
        entreprise.certifications.some((certification) =>
          this.getIfStringMatchSearch(certification.nom)
        ) ||
        entreprise.type_productions.some((typeProduction) =>
          this.getIfStringMatchSearch(typeProduction.nom)
        )
      );
    },
    faisRecherche() {
      this.chargeListeEntreprises();
      RequeteRechercheHandler.restartTimeout();
    },
    removeFiltre(filtre: Filtre) {
      RequeteRechercheHandler.restartTimeout();
      filtre.estActif = false;
      this.chargeListeEntreprises();
    },
    reinitialiseFiltres() {
      this.indexPage = 0;
      let nbFiltresActifs = 0;
      for (const filtre of this.filtres.values()) {
        if (filtre.estActif) {
          nbFiltresActifs++;
        }
        filtre.estActif = false;
      }
      if (nbFiltresActifs > 0) {
        RequeteRechercheHandler.restartTimeout();
      }
      this.chargeListeEntreprises();
    },
    sendRecherche() {
      const filtres = Array.of(...this.filtres.values())
        .filter((filtre) => filtre.estActif)
        .map((filtre) => {
          return {
            type: filtre.type,
            id: filtre.id,
            nom: filtre.nom,
          };
        });
      const rechercheBody = {
        recherche: this.recherche,
        filtres: filtres,
      };
      this.$store.dispatch('recherche/saveRecherche', rechercheBody);
    },
    rechercheFiltreItem(id: number, type: FiltreType) {
      if (id === undefined) {
        return undefined;
      } else {
        const key = `${type}-${id}`;
        return this.filtres.get(key);
      }
    },
    getListeEntreprisesWithFiltres(listeEntreprises: Array<Entreprise>) {
      return listeEntreprises.map((entreprise) => {
        entreprise.entreprise_produits.map((produit) => {
          // produit.pivot.conservation = this.rechercheFiltreItem(
          //   produit.pivot.conservation_id,
          //   FiltreType.conservation
          // );
          // produit.pivot.specificite = this.rechercheFiltreItem(
          //   produit.pivot.specificite_id,
          //   FiltreType.specificite
          // );
          produit.type_transaction = this.rechercheFiltreItem(
            produit.type_transaction_id,
            FiltreType.typeTransaction
          );
          produit.volume = this.rechercheFiltreItem(
            produit.volume_id,
            FiltreType.volume
          );
          return produit;
        });
        return entreprise;
      });
    },
  },
  mounted() {
    RequeteRechercheHandler.setRequestFunction(this.sendRecherche);
    this.recherche =
      this.$route.query.recherche === undefined
        ? ''
        : this.$route.query.recherche;
    this.indexPage =
      this.$route.query.page > 0 ? parseInt(this.$route.query.page) : 0;

    this.$store
      .dispatch('entreprises/recupereListeEntreprises')
      .then((entreprises) => {
        EventSynchronisationHandler.on(
          EventSynchronisationName.filtresPret,
          () => {
            this.listeEntrepriseCache =
              this.getListeEntreprisesWithFiltres(entreprises);
            this.chargeListeEntreprises();
            this.entreprisesPretes = true;
          }
        );
      });
  },
});
</script>

<style scoped lang="scss">
@import '../assets/scss/_colors.scss';

:not(nav) a {
  &:link,
  &:visited {
    color: black;
    text-decoration: none;
  }

  &:focus,
  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.card {
  text-align: left;
}

button {
  border: none;
  min-height: 29px;
}

button.bouton-reinitialisation {
  background: $blanc;
  color: $bleuPale;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

h3 {
  color: $vert;
  padding-bottom: 10px;
}

h5 {
  display: none;
}

// Je ne suis vraiment pas certain de savoir comment cela a pu se retrouver dans le code. Je commente pour le moment.
// input::-webkit-search-cancel-button {
//   position: relative;
//   right: 20px;

//   -webkit-appearance: none;
//   height: 20px;
//   width: 20px;
//   border-radius: 10px;
//   background: red;
// }

input[type='search'] {
  border: 1px solid $grisBordure;

  &:focus {
    outline: 1px solid $vert;
  }
}

main {
  // grid
  // aside = 2; reste = 8
  display: grid;
  grid-template-columns: 2fr 8fr;
}

.close-btn {
  cursor: pointer;
  font-size: 30px;
  padding: 10px 25px;
  text-align: right;
}

.carte-avec-marge-interieure {
  margin: 0 20px 0 20px;
  padding: 20px 0 20px 0;
  border-bottom: 1px solid $grisSeparateurs;
  border-top: 1px solid $grisSeparateurs;
}

.conteneur-bouton-recherche {
  display: grid;
  grid-template-columns: 1fr;
}

.conteneur-etiquettes {
  // flex
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 25px;

  .etiquette {
    color: $bleuPale;
    background-color: $bleuTresPale;
    padding: 10px;
    margin-right: 15px;
    // pour éviter que deux rangées d'étiquettes soient collées
    margin-top: 5px;

    &:hover {
      background-color: $bleuPale;
      color: $blanc;
    }
  }

  .libelle-etiquette {
    padding-right: 15px;
  }
}

.conteneur-grille {
  // grid; contenu, puis marge
  display: grid;
  grid-template-columns: 8fr 1fr;
}

.conteneur-zone-rechercher {
  // grid
  display: grid;
  grid-template-columns: 8fr 2fr;
  margin-top: 18px;
}

// pour que les icônes Font Awesome ne soient pas directement collés sur le reste du texte
.fa-facebook,
.fa-file-export,
.fa-twitter,
.fa-instagram {
  margin-right: 10px;
}

.fa-instagram {
  width: 16px;
}
.site-web {
  margin-left: 10px;
}

.fermeture-etiquette {
  &:hover {
    cursor: pointer;
  }
}

.filtres-fixes {
  background-color: white;
  height: 100%;
  overflow-x: scroll;
  position: fixed;
  right: 0;
  top: 0;
  border: 1px solid $grisBordurePagination;
}

.grid-main-et-marges {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  // pour repousser de 70px le pied de page
  padding-bottom: 70px;
}

.grille-coordonnees {
  // grid
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 3fr 3fr 2fr;

  div {
    div {
      padding-top: 5px;
    }
  }
}

// cette classe s'est appelée .hamburger pendant un moment
.conteneur-sous-barre-recherche {
  font-weight: bold;
  // pour que le bouton soit ramené à gauche plutôt que centré
  margin: 20px 0 20px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.conteneur-sous-barre-recherche > button {
  border: 1px solid $grisBordure;
  color: $bleuFonce;
  // mention !important nécessaire ici
  background: white !important;

  // mention !important nécessaire ici
  font-weight: bold !important;
}

.bouton-recherche {
  color: white;
}

.ordi-de-bureau input {
  padding-left: 30px;

  // image
  background: url('../assets/img/magnifying-glass-solid.svg');
  background-position: 1% 50%;
  background-size: 16px 16px;
  background-repeat: no-repeat;
}

.pleine-largeur {
  height: 25px;
  width: 99%;
}

.separateur {
  border-bottom: 2px solid $grisSeparateurs;
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.texte-gris {
  color: $grisCaracteres;
}

// règles pour la section pagination
button.exportation {
  background: $bleuPale;
  border: none;
  color: $blanc;
  // height: 29px;
  // width: 162px;

  // flex
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $bleuFonce;
  }
}

.exportation {
  //padding-top: 10px;
}

button.rechercher {
  border: none;
  color: $blanc;
  background-color: $vert;
  height: 29px;

  &:hover {
    background-color: $bleuFonce;
  }
}

h3 {
  color: $vert;
  padding-bottom: 10px;
}

h5 {
  display: none;
}

main {
  // grid
  // aside = 2; reste = 8
  display: grid;
  grid-template-columns: 2fr 8fr;
}

.texte-gris {
  color: $grisCaracteres;
}

.conteneur-etiquettes {
  // flex
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 25px;
  padding-bottom: 20px;

  div {
    color: $bleuPale;
    background-color: $bleuTresPale;
    padding: 10px;
    margin-right: 15px;

    &:hover {
      background-color: $bleuPale;
      color: $blanc;
    }
  }

  .libelle-etiquette {
    padding-right: 15px;
  }
}

.conteneur-grille {
  padding-left: 25px;

  // grid; contenu, puis marge
  display: grid;
  grid-template-columns: 8fr 1fr;
}

.conteneur-zone-rechercher {
  margin-top: 18px;

  // grid
  display: grid;
  grid-template-columns: 8fr 2fr;
}

.conteneur-exportation-et-navigation {
  margin-top: 20px;
  // flex
  display: flex;
  align-items: center;
  flex-flow: wrap;
  justify-content: space-between;
}

.nav-pagination {
  // flex
  display: flex;
  align-items: center;
  justify-content: flex-end;
  user-select: none;

  a {
    text-decoration: none !important;
  }

  span {
    // flex
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      background: $grisCaracteres;
      color: $blanc;
      height: 100%;
      width: 100%;
    }
  }

  & > * {
    border: 1px solid $grisBordurePagination;
    height: 28px;
    width: 29px;

    // flex
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      cursor: text !important;
    }

    &.active {
      background: $grisCaracteres;
      color: white;
    }

    &:hover {
      color: $blanc;
      background-color: $grisSeparateurs;
      cursor: pointer;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

// media queries
@media screen and (max-width: 1000px) {
  input {
    padding-left: 15px;
  }

  main {
    display: block;
  }

  .bouton-recherche {
    // image
    background: $vert;
    // background: url('../assets/img/magnifying-glass-solid.svg') $vert;
    // background-position: center center;
    // background-size: 16px 16px;
    // background-repeat: no-repeat;

    &:hover {
      background-color: $bleuFonce;
    }
  }

  .conteneur-sous-barre-recherche {
    background: $grisPiedDePage;
    height: 58px;
    padding: 5px 15px 5px 15px;
  }

  .grille-coordonnees {
    display: block;
  }

  // pour espacer un peu les trois blocs
  .grille-coordonnees > div {
    padding-top: 20px;
  }

  .grille-coordonnees.div > div {
    margin-top: 10px;
    background-color: red;
  }

  .ordi-de-bureau {
    display: none;
  }
}

@media screen and (min-width: 1001px) {
  h3 {
    padding-left: 25px;
  }

  section {
    border-left: 2px solid $grisSeparateurs;
  }

  .bouton-recherche {
    background: $vert;

    &:hover {
      background-color: $bleuFonce;
    }
  }

  .filtres .close-btn {
    display: none;
  }

  // pour éviter qu'il y ait un décalage vers la droite en mode mobile
  // cette règle est rendue pertinente parce que la barre verticale grise n'est plus là en mobile
  .conteneur-etiquettes,
  .conteneur-grille {
    padding-left: 25px;
  }

  .conteneur-exportation-et-navigation {
    // pour éviter que le bouton bleu soit collé sur la ligne verticale grise en mobile, application de cette règle seulement en mode ordi de bureau
    margin-left: 25px;
  }

  .conteneur-zone-rechercher {
    padding-left: 25px;
  }

  .grille-coordonnees {
    margin-left: 25px;
  }

  .conteneur-sous-barre-recherche {
    padding-left: 25px;
  }

  // cette classe s'est appelée .hamburger pendant un moment
  .conteneur-sous-barre-recherche > button {
    display: none;
  }

  .ordi-mobile {
    display: none;
  }

  .separateur {
    margin-left: 25px;
  }
}
#fleche-gauche {
  margin-right: 10px;
}
#fleche-droite {
  margin-left: 10px;
}
.show-more {
  all: unset;
  cursor: pointer;
  color: $bleuPale;
  margin-left: 2%;
  font-size: 14px;
}

.export-buttons {
  display: flex;
  flex-direction: column;
}
.export-buttons button {
  margin: 2px;
}
</style>
