export enum FiltreType {
  typeProduction = 'typeProduction',
  typeClientelle = 'typeClientelle',
  produit = 'produit',
  conservation = 'conservation',
  volume = 'volume',
  typeTransaction = 'typeTransaction',
  certification = 'certification',
  specificite = 'specificite',
  conditionnements = 'conditionnements',
  typeContenants = 'typeContenants',
  dureeVies = 'dureeVies',
  codeBarres = 'codeBarres',
  quantiteMinimaleCommandes = 'quantiteMinimaleCommandes',
  disponibilites = 'disponibilites',
  permis = 'permis',
  certificationInstallations = 'certificationInstallations',
}
