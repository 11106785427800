<template>
  <header>
    <!-- navigation -->
    <Nav></Nav>

    <!-- début classe jumbo -->
    <div class="jumbo" v-show="this.$route.meta.cacheJumbotron === false">
      <!-- moitié de gauche -->
      <div class="gauche">
        <div class="marge"></div>
        <div class="contenu">
          <p class="text-contenu">
            Le répertoire agroalimentaire de la Chaudière-Appalaches facilite la
            prise de contact des producteurs de la région avec les diverses
            clientèles commerciales (B2B) de partout dans la province.
          </p>
        </div>
      </div>
      <!-- fin moitié de gauche -->

      <!-- début moitié de droite -->
      <div class="droite">
        <div class="contenu"></div>
        <div class="marge"></div>
      </div>
      <!-- fin moitié de droite -->
    </div>
    <!-- fin classe jumbo -->

    <div class="sous-jumbo">
      <div class="card gauche">
        <h1>Acheteur professionnel</h1>
        <div class="card__body">
          <div>
            <p>
              Le répertoire s'adresse aux dirigeant·es de restaurants, Centre de
              la petite enfance (CPE), Résidences de personnes âgées (RPA),
              épiceries, gîtes, hôtels, et toutes autres institutions, donc B2B.
            </p>
            <b>Bénéfice pour vous :</b>
            <ul>
              <li>
                - Trouver des produits locaux, sans intermédiaire, accessibles,
                variés, à proximité, cultivés ou transformés en
                Chaudière-Appalaches.
              </li>
            </ul>
          </div>
          <div class="buttons">
            <button class="button" @click="jumpToSection('recherche')">
              <font-awesome-icon :icon="['fa', 'magnifying-glass']" />
              <div>Consulter le répertoire</div>
            </button>
          </div>
        </div>
      </div>

      <div class="card droite">
        <h1>Producteur ou transformateur</h1>
        <div class="card__body">
          <div>
            <p>
              Faites connaître vos produits sur les marchés commerciaux comme :
              les restaurants, les Centres de la petite enfance (CPE), les
              résidences de personnes âgées, le secteur hôtelier, les épiceries
              ou d'autres institutions.
            </p>
            <div>
              <b>Bénéfice pour vous :</b>
              <ul>
                <li>- C'est simple et gratuit.</li>
                <li>
                  - C'est exclusif aux entreprises de la Chaudière-Appalaches.
                </li>
                <li>
                  - C'est une vitrine qui sera diffusée à grande échelle dans la
                  province auprès des clientèles cibles.
                </li>
              </ul>
            </div>
          </div>
          <div class="buttons">
            <button class="button" @click="showModal = true">
              <font-awesome-icon :icon="['fa', 'circle-play']" />

              <div>Comment ça marche ?</div>
            </button>

            <a
              class="button"
              href="/inscription"
              title="Page d'inscription du site"
            >
              <font-awesome-icon :icon="['fa', 'circle-check']" />

              <div>Je m'inscris</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <ModalVideo v-if="showModal" @close="showModal = false"></ModalVideo>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Nav from '@/components/Nav.vue';
import ModalVideo from './ModalVideo.vue';
export default defineComponent({
  name: 'Header',
  components: {
    Nav,
    ModalVideo,
  },
  computed: {
    videoUrl() {
      return `${process.env.VUE_APP_BACKEND_API_URL}videos/video_page_entreprise.mp4`;
    },
  },
  data() {
    return { showModal: false };
  },
  methods: {
    jumpToSection(sectionId) {
      // Use JavaScript to scroll to the target section
      var targetSection = document.getElementById(sectionId);

      if (targetSection) {
        targetSection.scrollIntoView({
          behavior: 'smooth', // Optional: Add smooth scrolling effect
        });
      }
    },
  },
});
</script>

<style lang="scss">
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_fonts.scss';
a:link,
a:visited {
  text-decoration: none !important;
}

.text-contenu {
  color: #004f74;
  font-size: 27px;
  font-family: $ptsansnarrow;
}

header > .jumbo {
  // règles générales
  color: $bleuFonce;
  min-height: 250px;

  // pour cacher le titre du site, comme il est sémantiquement utile, mais ne fait pas partie de la maquette sous forme de caractères
  h1 {
    display: none;
  }

  & .droite {
    // fond avec les gerbes de blés
    background-image: url('../assets/img/raca_fond-gerbes.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;

    // grid
    display: grid;
    grid-template-columns: 5fr 1fr;
  }

  & .gauche {
    text-align: left;

    // grid
    display: grid;
    align-items: center;

    & .contenu {
      min-height: 200px;

      // flex
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
}

// mixins pour media queries
@mixin gaucheMobileEtTablette {
  // dans ce mixin "&" signifie ".gauche" comme ce il s'emploie dans cette classe CSS

  grid-template-columns: 1fr 10fr 1fr;

  // pour éviter que le dernier paragraphe soit collé sur les gerbes de blé en mobile
  & .contenu {
    margin-bottom: 30px;
  }

  // pour espacer les paragraphes
  & .contenu > * {
    margin: 30px 0px 0px 0px;
    padding-right: 0;
    text-align: center;
  }

  & h2 {
    font-size: 2.5em;
  }
}

h2 {
  margin-bottom: 3%;
  font-family: $pt;
}
p {
  margin-bottom: 1%;
}
a {
  &:link,
  &:visited {
    color: $bleuFonce;
    text-decoration: underline;
  }
}

@mixin droiteMobileEtTablette($hauteur) {
  height: $hauteur;
}

// media queries
@media screen and (max-width: 600px) {
  .jumbo .gauche {
    @include gaucheMobileEtTablette();
  }

  .jumbo .droite {
    @include droiteMobileEtTablette(200px);
  }
}

// media queries
@media screen and (min-width: 601px) and (max-width: 825px) {
  .jumbo .gauche {
    @include gaucheMobileEtTablette();
  }

  .jumbo .droite {
    @include droiteMobileEtTablette(300px);
  }
}

// media queries
@media screen and (min-width: 826px) and (max-width: 900px) {
  .jumbo .gauche {
    @include gaucheMobileEtTablette();
  }

  .jumbo .droite {
    @include droiteMobileEtTablette(300px);
  }
}

// media queries
@media screen and (min-width: 901px) and (max-width: 1000px) {
  .jumbo .gauche {
    @include gaucheMobileEtTablette();
  }

  .jumbo .droite {
    @include droiteMobileEtTablette(350px);
  }
}

// media queries
@media screen and (min-width: 1001px) {
  header > .jumbo {
    // grid
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .jumbo .gauche {
    display: grid;
    grid-template-columns: 1fr 5fr;
    padding-right: 30px;
    background-color: $bleuTresPale;
  }

  // fond avec les jambes et le panier
  .jumbo .droite {
    background-image: url('../assets/img/raca_fond-gerbes.svg');
    background-color: $bleuTresPale;
    background-repeat: no-repeat, no-repeat;
    background-position: bottom, bottom;
    background-size: contain, contain;

    & .contenu {
      // image
      background-image: url('../assets/img/raca_panier-fruits-legumes.png');
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: contain;
    }
  }
}

.sous-jumbo {
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  flex-wrap: wrap;
  padding: 30px 50px;
  gap: 10px;
  text-align: left;
  background-color: #eaf0f480;
  @media screen and (max-width: 600px) {
    padding: 30px 10px;
  }
}

.sous-jumbo .card {
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  flex-basis: 40%;
  background-color: #ffffff;
  line-height: 1.5;
  box-shadow: 0px 3px 6px #0000001a;
  li {
    list-style: none;
    padding-left: 10px;
    text-indent: -10px;
  }
  @media screen and (max-width: 990px) {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

.sous-jumbo .card {
  h1 {
    font-size: 27px;
    font-family: $ptsansnarrow;
    padding: 20px;
    text-align: start;
    color: white;
  }
  .card__body {
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .button {
    margin: 0;
    max-width: fit-content;
    height: auto;
    padding: 10px 15px;
    justify-content: flex-start;
    gap: 10px;
    //min-width: 40%;
    color: #ffffff;
    border: none;
    color: #ffffff;
    //width: 300px;
    margin: 3% 0 3%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .buttons {
    display: flex;
    gap: 2vh;
    flex-wrap: wrap;
  }
}

.sous-jumbo .card.gauche {
  & h1 {
    background-color: #004f74;
  }
  & .button {
    background-color: #004f74;
  }
}

.sous-jumbo .card.droite {
  & h1 {
    background-color: #7a9b06;
  }
  & .button {
    background-color: #7a9b06;
  }
}
</style>
