<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <h2>Informations supplémentaires</h2>
              <button @click="$emit('close')">
                <span class="close">&times;</span>
              </button></slot
            >
          </div>

          <div class="modal-body">
            <slot name="body">
              <div>
                <div class="informations">
                  <h1>{{ data.nom }}</h1>
                  <h2 v-if="data.nomContact">
                    {{ data.nomContact }}
                  </h2>
                  <div class="sous-informations">
                    <p v-if="data.courriel">
                      <font-awesome-icon :icon="['far', 'envelope']" />
                      <a :href="`mailto:${data.courriel}`">
                        {{ data.courriel }}
                      </a>
                    </p>
                    <p v-if="data.facebook">
                      <font-awesome-icon :icon="['fab', 'facebook']" />
                      <a :href="data.facebook" target="_blank"
                        >Lien vers Facebook</a
                      >
                    </p>
                    <p v-if="data.twitter">
                      <font-awesome-icon :icon="['fab', 'twitter']" />
                      <a :href="data.twitter" target="_blank"
                        >Lien vers twitter</a
                      >
                    </p>
                    <p v-if="data.instagram">
                      <font-awesome-icon :icon="['fab', 'instagram']" />
                      <a :href="data.instagram" target="_blank"
                        >Lien vers Instagram</a
                      >
                    </p>
                    <p v-if="data.siteWeb">
                      <font-awesome-icon :icon="['far', 'globe']" />
                      <a :href="data.siteWeb" target="_blank">{{
                        data.siteWeb
                      }}</a>
                    </p>
                  </div>
                </div>

                <div class="certifications">
                  <h2>Certifications</h2>
                  <ul>
                    <li
                      class="certification"
                      v-for="certification in entreprise_certifications"
                      :key="certification.id"
                    >
                      {{ certification.nom }}
                    </li>
                  </ul>
                </div>
                <hr />
                <div
                  class="produits"
                  v-for="produit in entreprise_produits"
                  :key="produit.id"
                >
                  <h2>{{ produit.produit.nom }}</h2>
                  <ul class="specificite">
                    <h3>Spécificités</h3>
                    <li
                      v-for="specificite in produit.specificites"
                      :key="specificite.id"
                    >
                      {{ specificite.nom }}
                    </li>
                  </ul>
                  <ul class="conservation">
                    <h3>Type de conservation</h3>
                    <li
                      v-for="conservation in produit.conservations"
                      :key="conservation.id"
                    >
                      {{ conservation.nom }}
                    </li>
                  </ul>
                  <ul class="volume">
                    <h3>Volume</h3>
                    <li>
                      {{ produit.volume.nom }}
                    </li>
                  </ul>
                </div>
              </div>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button class="exportation" @click="exportPDF()">
                <font-awesome-icon :icon="['far', 'file-export']" />
                Exporter en PDF
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script scoped lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
  name: 'ModalEntreprise',
  data() {
    return {
      data: {},
      entreprise_produits: {},
      entreprise_certifications: {},
    };
  },
  methods: {
    exportPDF() {
      let loader = this.$loading.show();
      console.log(loader);
      const url = `${process.env.VUE_APP_BACKEND_API_URL}api/generateSingleEntreprisePdf`;

      var entrepriseId = 'id=' + this.data.id;

      // Construisez l'URL complète avec les paramètres de requête
      const fullUrl = `${url}?${entrepriseId}`;

      // Utilisez Axios pour effectuer une requête GET
      axios
        .get(fullUrl, { responseType: 'arraybuffer' })
        .then(function (response) {
          // Créez un objet Blob avec les données du PDF
          const blob = new Blob([response.data], { type: 'application/pdf' });

          // Créez un lien (anchor) pour le téléchargement du PDF
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Entreprise.pdf';

          // Ajoutez le lien à la page et déclenchez le téléchargement
          document.body.appendChild(link);
          loader.hide();
          link.click();

          // Nettoyez le lien après le téléchargement
          document.body.removeChild(link);
        })
        .catch(function (error) {
          // Gérez les erreurs
          console.error('Erreur lors du téléchargement du PDF:', error);
        });
    },
    updateData(entreprise) {
      this.data = entreprise;
      this.entreprise_produits = entreprise.entreprise_produits;
      this.entreprise_certifications = entreprise.certifications;
    },
  },
});
</script>

<style scoped lang="scss">
@import '../assets/scss/_colors.scss';
.informations {
  padding: 20px;
  text-align: center;
}
.sous-informations {
  text-align: start;
  padding-bottom: 10px;
}

.sous-informations a {
  margin-left: 5px;
}

ul li {
  list-style: inside;
  text-transform: capitalize;
}
.specificite {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.conservation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.volume {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.produits {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2% 5% 2% 0px;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 2%;
}
.produits h2 {
  text-transform: capitalize;
  width: 13%;
}
.certifications {
  display: flex;
  margin-bottom: 2%;
}
.certifications ul {
  margin-left: 8%;
}

h3 {
  color: $vert;
  padding-bottom: 10px;
}
.modal-body h2 {
  text-align: left;
  font-size: 20px;
}

h2 {
  color: $bleuFonce;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60vw;
  margin: 0px auto;
  padding: 50px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: scroll;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-header h2 {
  text-align: left;
  color: $bleuFonce;
  font-size: 25px;
  margin: 0px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header button {
  all: unset;
  cursor: pointer;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.close {
  font-size: 45px;
  font-weight: 600;
  color: $bleuFonce;
}

// media queries
@media screen and (max-width: 720px) {
  .modal-header {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .modal-header h2 {
    text-align: center;
  }
  .certifications {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  .produits {
    flex-direction: column;
    margin: 15px 0px 5px;
  }
  .specificite {
    margin: 5px 0 15px;
  }
  .conservation {
    margin: 5px 0 15px;
  }
  .volume {
    margin: 5px 0 15px;
  }
}
button.exportation {
  background: $bleuPale;
  border: none;
  color: $blanc; //
  height: 29px; // width: 162px; // flex display: flex; align-items: center;
  justify-content: center;
  &:hover {
    background-color: $bleuFonce;
  }
}
</style>
