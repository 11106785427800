<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <button @click="$emit('close')">
                <span class="close">&times;</span>
              </button>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <video controls>
                <source :src="videoUrl" type="video/mp4" />
                Votre navigateur ne prend pas en charge la vidéo HTML.
              </video>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"> </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script scoped lang="ts">
export default {
  name: 'ModalVideo',
  computed: {
    videoUrl() {
      return `${process.env.VUE_APP_BACKEND_API_URL}videos/video_page_entreprise.mp4`;
    },
  },
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60vw;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-header {
  text-align: right;
}

.modal-header button {
  all: unset;
  cursor: pointer;
}
.close {
  font-size: 45px;
  font-weight: 600;
}

.modal-body {
  margin: 20px 0;
  text-align: center;
}

.modal-body video {
  width: 100%;
}

.modal-default-button {
  float: right;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
