import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hauteur-de-la-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_CookieDialog = _resolveComponent("CookieDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.currentRouteIsInscription)
      ? (_openBlock(), _createBlock(_component_Header, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    (!_ctx.currentRouteIsInscription)
      ? (_openBlock(), _createBlock(_component_Footer, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_CookieDialog, { primary_color: "#7A9B06" })
  ]))
}