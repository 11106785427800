class RequeteRechercheHandlerClass {
  private timeToWait = 10000;
  private task?: number;
  private requestFunction: () => void;

  public setRequestFunction(requestFunction: () => void) {
    this.requestFunction = requestFunction;
  }

  public clearTimeout() {
    if (this.task) {
      clearTimeout(this.task);
    }
  }

  public restartTimeout() {
    this.clearTimeout();
    this.task = setTimeout(() => {
      this.task = undefined;
      this.requestFunction();
    }, this.timeToWait);
  }
}

const RequeteRechercheHandler = new RequeteRechercheHandlerClass();
export default RequeteRechercheHandler;
