<template>
  <head>
    <title>Erreur 404 | RACA</title>
  </head>
  <div class="page-404">
    <div class="moitie-gauche">
      <div class="marge"></div>
      <div class="contenu-gauche">
        <h2>Erreur 404</h2>

        <p>Cette page ne semble malheureusement</p>
        <p>pas exister sur notre site.</p>

        <p class="revenir-acceuil">
          <a href="/" title="Page d'accueil"
            ><font-awesome-icon
              :icon="['fas', 'arrow-left']"
              class="logo-fleche"
            />Revenir à l'accueil</a
          >
        </p>
      </div>
    </div>

    <div class="moitie-droite">
      <div class="contenu-droite">
        <div class="loupe-erreur-404"></div>
      </div>
      <div class="marge"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../assets/scss/_colors.scss';

a {
  font-size: 14px;
  &:link,
  &:visited {
    color: $bleuFonce;
    text-decoration: none;
  }

  &:focus,
  &:hover,
  &:active {
    text-decoration: underline;
  }
}
p {
  color: $bleuFonce;
  font-size: 22px;
}
.revenir-acceuil {
  margin-top: 10%;
}
.contenu-gauche {
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1000px) {
    text-align: center;
    justify-content: center;
    width: 100%;
  }
}

// .loupe-erreur-404 {
//   margin-top: 50px;

//   background-image: url('../assets/img/loupe-erreur-404.png');
//   background-size: cover;
//   // height: 100px;
//   // width: 200px;
//   height: 100%;
//   width: 100%;
// }

.moitie-droite {
  // fond avec les gerbes de blés
  background-image: url('../assets/img/raca_fond-gerbes.svg'),
    url('../assets/img/loupe-erreur-404.png');
  background-repeat: no-repeat, no-repeat;
  background-position: bottom, 50% 50%;
  background-size: contain, 75%;

  // grid
  display: grid;
  grid-template-columns: 5fr 1fr;
}

.moitie-gauche {
  // fond avec les gerbes de blés
  background-image: url('../assets/img/raca_fond-gerbes.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;

  // grid
  display: grid;
  grid-template-columns: 1fr 5fr;
  @media screen and (max-width: 1000px) {
    background: none;
    display: flex;
    margin: 10%;
  }
}
.logo-fleche {
  width: 10px;
  margin-right: 1%;
}
.page-404 {
  // background: $bleuTresPale url('../assets/img/raca_fond-gerbes.svg') repeat-x
  //   left bottom fixed;

  // background: $bleuTresPale url('../assets/img/raca_fond-gerbes.svg');
  // pour que le contenu prenne la hauteur de la page

  background-color: $bleuTresPale;
  min-height: calc(100vh - 120px);

  //
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-image: url('../assets/img/raca_fond-gerbes.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
  }
}
@media screen and (max-width: 1000px) {
  .moitie-droite {
    display: none;
  }
  .marge {
    display: none;
  }
}
</style>
